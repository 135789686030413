//Gurbani search//
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useOutletContext } from "react-router";
import { Link, useLocation } from "react-router-dom";
import '../assets/css/dashboard.css';
import '../assets/css/style.css';
import Axios from 'axios';
import { API } from "../config/api";
import { ApiHelper } from '../helpers/ApiHelper';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import VirtualKeyboard from '../components/VirtualKeyboard';
import charMap from '../components/GurumukhiAscii';
import { Color } from 'react-input-color';

const transliterateToGurumukhi = (input) => {
    return input
        .split('')
        .map((char) => charMap[char] || char)
        .join('');
};

function SearchResultPreview() {
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state
    const [loader, setLoader] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [pageData, setpageData] = useState([]);
    const [word, setWord] = useState('');
    const [pageNo, setPageNo] = useState(0);




    useEffect(() => {
        if (data.Word) {
            setWord(data.Word);
            console.log('getSearch result', data.Word);

            getSearchResult(data.Word)
        }
    }, [])
    /* const getSearchResult = async (word, page) => {
        setLoader(true)
        await Axios.get(API.getResourceResult + '?keyword=' + word + '&alpha=alpha&page=' + page)
            .then((resData) => {
                setLoader(false)
                console.log('getSearch result', resData.data);
                setSearchData(resData.data.lines)
                setpageData(resData.data.search_results_info)
            })
            .catch((err) => {
                setLoader(false)
                console.log(err);
            })
    } */
    const getSearchResult = async (word) => {
        setLoader(true)
        const params = new FormData();
        params.append('start', '0');
        params.append('length', '20');
        params.append('searchType', 'PHRASE');
        params.append('language', 'PUNJABI');
        params.append('scripture', 'ggs');
        params.append('searchData', word);
        params.append('author', '');
        params.append('raag', '');
        params.append('page', '0');
        params.append('page_from', '1');
        params.append('page_to', '1430');
        params.append('tableId', '');
        params.append('case', '');

        console.log('para', params)
        await Axios.post(API.getResSearchResultPreview, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((resData) => {
                setLoader(false)
                console.log('getSearchRESULT', resData.data);
                setSearchData(resData.data.lines)
                setpageData(resData.data.search_results_info)
            })
            .catch((err) => {
                setLoader(false)
                console.log(err);
            })
    }
    /*  const handleAlphaClick = (alpha) => {
         setWord(alpha)
         setPageNo(0)
         getSearchResult(alpha, 0)
     };
     const handlePreviousClick = (toNo) => {
         if(toNo> 0) {
             let nextNo= parseInt(toNo) - 1;
             setPageNo(nextNo)
             getSearchResult(word,nextNo)
         }
     };
     const handleNextClick = (toNo) => {
         let totalPage = (parseInt(pageData.total_results) / 25) 
         if(toNo< parseInt(totalPage)+1) {
             let nextNo= parseInt(toNo) + 1;
             setPageNo(nextNo)
             getSearchResult(word,nextNo)
         }
     }; */
    return (
        <div>
            <section className='browse_by_letter p-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='in-act-wrapper'>
                            <h2 className='text-dark mb-4'>Sri Guru Granth Sahib</h2>
                        </div>
                        <div className='in-act-wrapper'>

                            <div className='actions-mains '>
                                <h4 className='text-dark'>Find Results : {data.Word} (PHRASE)</h4>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            <section>
                <div className='container'>
                    <div className='d-flex flex-column'>
                        <div className='ang-display mt-5'>
                            <h1>Displaying Records {pageData.showing_from} - {pageData.showing_to} of {pageData.total_results}.</h1>
                            <div className='ang-wrapper'>
                                {searchData.map((item, index) => {
                                    return (
                                        <div className='ang-itm'>
                                            <div className='' >
                                                <strong>
                                                    {index + pageData.showing_from}. &nbsp;
                                                    <Link to={`/guru-granth-sahib/ang/${item.pageno}/line/${item.lineno}`}>
                                                        Ang {item.pageno} Line {item.pagelineno} {item.raag} : {item.author}
                                                    </Link>
                                                    <br />
                                                    <Link to={`/guru-granth-sahib/shabad/${item.shabad_id}/line/${item.shabadlineno}`}>
                                                        or Go to Shabad
                                                    </Link>
                                                </strong>
                                                <div className="lang-3">{item.punjabi}</div>
                                                <div className="lang-4">{item.translit}</div>
                                                <div className="lang-4">{item.hindi}</div>
                                                <div className="lang-5">{item.english}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SearchResultPreview