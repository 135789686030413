import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function AnandBhog() {
    return (
        <div>
            <SGCommonPage title="Anand Sahib (Bhog)" apiName="anand-sahib-bhog" audioName="anand_sahib_bhog" tlPage="2" /> 
        </div>
    )
}

export default AnandBhog