import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function SalokFarid() {
    return (
        <div>
            <SGCommonPage title="Salok Farid ji" apiName="salok-farid-ji" audioName="salok_farid_ji" tlPage="12" /> 
        </div>
    )
}

export default SalokFarid