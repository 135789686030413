import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from "react-router";
import { Link, useLocation, useParams } from "react-router-dom";
import '../../../assets/css/dashboard.css';
import '../../../assets/css/author.css';
import Axios from 'axios';
import { API } from "../../../config/api";
import { ApiHelper } from '../../../helpers/ApiHelper';

function MaansarovarIndex() {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const { word } = useParams();
    const [indexArr, setIndexArr] = useState([]);
    useEffect(() => {
        getIndex();
    }, [])
    const getIndex = async () => {
        setLoader(true)
        await ApiHelper.get(API.getMaansarovarIndex + word)
            .then((resData) => {
                setLoader(false);
                console.log('Index', resData.data.quotations);
                setIndexArr(resData.data.quotations);
            })
            .catch((err) => {
                setLoader(false);
                console.log(err, 'err');
            });
    }
    return (
        <div>
            <section className='inner-actions p-4' >
                <div className='container'>
                    <h3 className='text-dark mb-3 text-center' >Maansarovar - Quotations for {word}</h3>

                </div>
                <button className='ang-btn' style={{float:'right'}} onClick={() => navigate('/maansarovar')}>Search Page</button>
            </section>


            <section>
                <div className='container'>
                    <div className='d-flex flex-column'>
                        <div className='ang-display '>
                            <div className="section_title">
                                <span className="col_sl_no"> No</span>
                                <span className="col_sl_name">Quotation</span>
                                <span className="col_section_no">SGGS Ang</span>
                                <br className="clearer" />
                            </div>

                            <div className='ang-wrapper '>
                                {indexArr.map((item, index) => {
                                    return (
                                        <div className='ang-itm' >
                                            <div className="section_line line row1">
                                                <span className="col_sl_no sec-no">{index + 1}</span>
                                                <Link to={`/guru-granth-sahib/ang/${item.pageno}`} className="col_sl_no sec-nos">
                                                    <span >{item.quotation}</span></Link>
                                                <Link to={`/guru-granth-sahib/ang/${item.pageno}`}><span className="col_section_no sec-no">{item.pageno}</span></Link>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MaansarovarIndex