import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation} from "react-router-dom";
import Layout from "../views/layout";
import Home from "../views/Home";
import GgsIntroduction from "../views/GGS/GgsIntro";
import GurbaniSearch from "../views/GurbaniSearch";
import GurbaniSearching from "../views/GurbaniSearching";
import DiscoverMore from "../views/DiscoverMore";
import AngByAng from "../views/GGS/AngByAng";
import SggsInWorldLanguage from "../views/GGS/SggsWorldLanguage";
import SggsShabadIndex from "../views/GGS/SggsIndex";
import AuthorIndex from "../views/GGS/AuthorIndex";
import GgsAdvancedSearch from "../views/GGS/GgsAdvanceSearch";
import Feedback from "../views/Feedback";
import AuthorRaga from "../views/GGS/AuthorRaga";
import SiteMap from "../views/SiteMap";
import SitePreference from "../views/SitePreference";
import AkIntroduction from "../views/AK/AkInro";
import BgvIntroduction from "../views/BGV/BgvIntro";
import DgsIntroduction from "../views/DGS/DsgIntro";
import ShabadLine from "../views/GGS/GGSShabadLine";
import AngLine from "../views/GGS/AngLine";
import PageByPage from "../views/AK/PageByPage";
import EnglishIndex from "../views/AK/EnglishIndex";
import HindiIndex from "../views/AK/HindiIndex";
import PunjabiIndex from "../views/AK/PunjabiIndex";
import AkShabadLine from "../views/AK/AkShabadLine";
import ChapterIndex from "../views/AK/ChapterIndex";
import ChapterName from "../views/AK/ChapterName";
import GGSVerse from "../views/GGS/GGSVerse";
import SGGKSearch from "../views/Resources/SGGK/SGGKSearch";
import AkAdvancedSearch from "../views/AK/AkSearch";
import BgvAdvancedSearch from "../views/BGV/BgvSearch";
import DgsAdvancedSearch from "../views/DGS/DgsSearch";
import KsAdvancedSearch from "../views/KS/KsSearch";
import BnlAdvancedSearch from "../views/BNL/BnlSearch";
import PauriByPauri from "../views/BGV/PauriByPauri";
import VaarIndex from "../views/BGV/VaarIndex";
import DGPageByPage from "../views/DGS/DGPageByPage";
import DgChapterPb from "../views/DGS/DgChapterIndexPB";
import DgChapterEn from "../views/DGS/DgChapterIndexEN";
import ISearchGurbani from "../views/Resources/ISearchGurbani";
import PageLine from "../views/DGS/PageLine";
import DgShabadLine from "../views/DGS/DgShabad";
import DgVerse from "../views/DGS/DgVerse";
import KabitByKabit from "../views/KS/KabitByKabit";
import Ghazalas from "../views/BNL/Ghazals";
import Rubaayee from "../views/BNL/Rubaayee";
import Zindginama from "../views/BNL/Zindginama";
import Ganjnama from "../views/BNL/Ganjnama";
import JotBikasPb from "../views/BNL/JotBikasPb";
import JotBikasPersian from "../views/BNL/JotBikasPersian";
import Rahitnama from "../views/BNL/Rahitnama";
import Tankahnama from "../views/BNL/Tankahnama";
import BnlShabadLine from "../views/BNL/BnlShabad";
import BnlPageLine from "../views/BNL/BnlPageLine";
import BnlVerse from "../views/BNL/BnlVerse";
import JapjiSahib from "../views/SG/Nitnem/JapjiSahib";
import JaapSahib from "../views/SG/Nitnem/JaapSahib";
import TvaiPrasadh from "../views/SG/Nitnem/TvaiPrasadhSavaiye";
import ChaupaiSahib from "../views/SG/Nitnem/ChaupaiSahib";
import AnandSahib from "../views/SG/Nitnem/AnandSahib";
import RehraasSahib from "../views/SG/Nitnem/RehraasSahib";
import KirtanSohila from "../views/SG/Nitnem/Kirtansohila";
import AkalUstati from "../views/SG/DasamGranth/AkalUstati";
import BachitarNatak from "../views/SG/DasamGranth/BachitarNatak";
import ShabadHazare from "../views/SG/DasamGranth/ShabadHazare";
import DGTvai from "../views/SG/DasamGranth/TvaiPrasadh";
import AnandBhog from "../views/SG/GuruGranthSahib/AnandSahibBhog";
import Aarti from "../views/SG/GuruGranthSahib/Aarti";
import Laavan from "../views/SG/GuruGranthSahib/Laavan";
import AsaKiVaar from "../views/SG/GuruGranthSahib/AsaKiVaar";
import SukhmaniSahib from "../views/SG/GuruGranthSahib/SukhmaniSahib";
import SidhGosht from "../views/SG/GuruGranthSahib/SidhGosht";
import RamkaliSadh from "../views/SG/GuruGranthSahib/RamkaliSadh";
import DhakaneeOankaar from "../views/SG/GuruGranthSahib/DhakaneeOankaar";
import Baavan from "../views/SG/GuruGranthSahib/Baavan";
import GGSShabadHazare from "../views/SG/GuruGranthSahib/GGSShabadHazare";
import BaarahMaaha from "../views/SG/GuruGranthSahib/BaarahMaaha";
import SukhmanaSahib from "../views/SG/GuruGranthSahib/SukhmanaSahib";
import DukhBhanjani from "../views/SG/GuruGranthSahib/DukhBhanjani";
import Salok from "../views/SG/GuruGranthSahib/Salok";
import Gathaa from "../views/SG/GuruGranthSahib/Gathaa";
import Phunhay from "../views/SG/GuruGranthSahib/Phunhay";
import Chaubolay from "../views/SG/GuruGranthSahib/Chaubolay";
import SalokKabeer from "../views/SG/GuruGranthSahib/SalokKabeer";
import SalokFarid from "../views/SG/GuruGranthSahib/SalokFarid";
import SavaiyeM1 from "../views/SG/GuruGranthSahib/SavaiyeM1";
import SavaiyeM2 from "../views/SG/GuruGranthSahib/SavaiyeM2";
import SavaiyeM3 from "../views/SG/GuruGranthSahib/SavaiyeM3";
import SavaiyeM4 from "../views/SG/GuruGranthSahib/SavaiyeM4";
import SavaiyeM5 from "../views/SG/GuruGranthSahib/SavaiyeM5";
import SalokM9 from "../views/SG/GuruGranthSahib/SalokM9";
import SundarGutka from "../views/SG/SundarGutka";
import ISearch from "../views/Resources/ISearch";
import SearchGurbaniDV from "../views/Resources/SearchGurbaniDV";
import Mahankosh from "../views/Resources/GSRM/Mahankosh.js";
import MahankoshView from "../views/Resources/GSRM/MahankoshView.js";
import GurbaniRaags from "../views/Resources/GurbaniRaag/Gurbaniraags";
import SGGKView from "../views/Resources/SGGK/SGGKView.js";
import SggskSearch from "../views/Resources/SGGSKhosh/Sggsk.js";
import SggskView from "../views/Resources/SGGSKhosh/SggskView.js";
import Maansarovar from "../views/Resources/MAANSARO/Maansarovar.js";
import MaansarovarView from "../views/Resources/MAANSARO/MaansarovarView.js";
import MaansarovarIndex from "../views/Resources/MAANSARO/MaansarovarIndex.js";
import SNPrakash from "../views/Resources/SNP/SNPrakash.js";
import SNPrakashSectn1 from "../views/Resources/SNP/SNPrakashSectn.js";
import SNPrakashSectn from "../views/Resources/SNP/SNPrakashSectn.js";
import SNPrakashView from "../views/Resources/SNP/SNPrakashView.js";
import SGPSGSearch from "../views/Resources/SGPSGranth/SGPSG.js";
import SGPSGIndex from "../views/Resources/SGPSGranth/SGPSGIndex.js";
import SGPSGView from "../views/Resources/SGPSGranth/SGPSGView.js";
import SGPSGVolume from "../views/Resources/SGPSGranth/SGPSGVolume.js";
import FWTSearch from "../views/Resources/FWT/FWTSearch.js";
import FWTIndex from "../views/Resources/FWT/FWTIndex.js";
import FWTView from "../views/Resources/FWT/FWTView.js";
import FWTSearchView from "../views/Resources/FWT/FWTSearchView.js";
import SNPSearchView from "../views/Resources/SNP/SNPrakashSearchView.js";
import SgpsgSearchView from "../views/Resources/SGPSGranth/SgpsgSearchView.js";
import SearchResultPreview from "../components/SearchResultPreview.js";
import Timing from "../views/Resources/GurbaniRaag/Timing.js";
import Taal from "../views/Resources/GurbaniRaag/Taal.js";
import Golssary from "../views/Resources/GurbaniRaag/Glossary.js";
import Muscical from "../views/Resources/GurbaniRaag/Musical.js";
import HukumIndex from "../views/Resources/Hukumnama/HukumIndex.js";
import SriDarbarSahib from "../views/Resources/Hukumnama/SDS.js";
import CyberHukumnama from "../views/Resources/Hukumnama/CyberHukumnama.js";
import SGGDSearch from "../views/Resources/SGGD/SGGDSeach.js";
import SGGDView from "../views/Resources/SGGD/SGGDView.js";
import SGGDSeachView from "../views/Resources/SGGD/SGGDSearchView.js";
import HukumAng from "../views/Resources/Hukumnama/HukumAng.js";
import SriRaag from "../views/Resources/GurbaniRaag/SriRaag.js";
import Unicode from "../views/UnicodeFonts.js";
import Sahiban from "../views/Resources/GuruSahiban/Sahiban.js";
import GuruNanak from "../views/Resources/GuruSahiban/GuruNanak.js";
import IndianClassical from "../views/Resources/ClassicalMusic/IndianClassical.js";
import BhattSahiban from "../views/Resources/Bhatts/BhattSahiban.js";
import VersePrintView from "../components/VersePrint.js";
import Kalshar from "../views/Resources/Bhatts/Kalshar.js";
import Salh from "../views/Resources/Bhatts/Salh.js";
import Mathura from "../views/Resources/Bhatts/Mathura.js";
import SattaBalwand from "../views/Resources/Bhatts/SattaBalwand.js";
import AngPrint from "../views/GGS/AngPrint.js";
import AkPagePrint from "../views/AK/PrintPage.js";
import PrintPauri from "../views/BGV/PrintPauri.js";
import DgPagePrint from "../views/DGS/DgPrintPage.js";
import KsPagePrint from "../views/KS/KsPrintPage.js";
import BnlPrintPage from "../views/BNL/BnlPrintPage.js";
import Jalap from "../views/Resources/Bhatts/Jalap.js";
import Balh from "../views/Resources/Bhatts/Balh.js";
import Bhalh from "../views/Resources/Bhatts/Bhalh.js";
import Mardana from "../views/Resources/Bhatts/Mardana.js";
import ShabadPrint from "../components/ShabadPrint.js";
import GgsShabadPrint from "../views/GGS/GgsShabdPrint.js";
import AkShabadPrint from "../views/AK/AkShabadPrint.js";
import DgShabadPrint from "../views/DGS/DgShabadPrint.js";
import BNlShabadPrint from "../views/BNL/BnlShabadPrint.js";
import BnlShabadPrint from "../views/BNL/BnlShabadPrint.js";
import GgsVersePrint from "../views/GGS/GgsVersePrint.js";
import DgVersePrint from "../views/DGS/DgVersePrint.js";
import BnlVersePrint from "../views/BNL/BnlVersePrint.js";
import SgPrintPage from "../views/SG/SgPrintPage.js";
import Kirat from "../views/Resources/Bhatts/Kirat.js";
import Nalh from "../views/Resources/Bhatts/Nalh.js";
import Haribans from "../views/Resources/Bhatts/Haribans.js";
import Gyand from "../views/Resources/Bhatts/Gyand.js";
import Bhika from "../views/Resources/Bhatts/Bhika.js";
import BabaSundar from "../views/Resources/Bhatts/BabaSundar.js";
import GuruNanak2 from "../views/Resources/GuruSahiban/GuruNanak2.js";
import GuruAngad from "../views/Resources/GuruSahiban/GuruAngad.js";
import GuruNanak3 from "../views/Resources/GuruSahiban/GuruNanak3.js";
import GuruNanak4 from "../views/Resources/GuruSahiban/GuruNanak4.js";
import GuruNanak5 from "../views/Resources/GuruSahiban/GuruNanak5.js";
import GuruNanak6 from "../views/Resources/GuruSahiban/GuruNanak6.js";
import GuruNanak7 from "../views/Resources/GuruSahiban/GuruNanak7.js";
import GuruNanak9 from "../views/Resources/GuruSahiban/GuruNanak9.js";
import GuruNanak10 from "../views/Resources/GuruSahiban/GuruNanak10.js";

const initialFormState = {
  translation: {
    ggs: {
      eng_mms: false,
      punj_mms: false,
      ggd: false,
      ft: false,
      fth: false,
      ss: false,
    },
    bgv: {
      teeka: false,
      teeka_roman: false,
      teeka_hindi: false,
    },
    dg: {
      teeka: false,
    },
    ks: {
      teeka: false,
      teeka_roman: false,
      teeka_hindi: false,
    },
    bnl: {
      teeka: false,
      teekahindi: false,
    },
    english: true,
  },
  transliteration: {
    roman: true,
    english: false,
    hindi: false,
    shahmukhi: false,
    main_lang: true,
    lareevar: false,
    lareevar_assist: false,
    punctuation: false,
    punctuation_assist: false,
  },
  displayMode: {
    split_view: false,
    center_align: false,
    dark_mode: false,    
  },
  font: {
    gurmukhi: {
      name: 'AnmolUniBani',
      color: '#333333',
      size: 18,
    },
    english: {
      name: 'arial',
      color: '#366732',
      size: 18,
    },
    hindi: {
      name: 'arial',
      color: '#880808',
      size: 18,
    },
    phonetic: {
      name: 'arial',
      color: '#06035b',
      size: 18,
    },
    attributes: {
      name: 'AnmolUniBani',
      color: '#670464',
      size: 18,
    }
  },
  mouseover_gurmukhi_dic: false,
  show_attributes: true,
  social_flag: false,
  share: {
    translation: {
      english: true,
    },
    transliteration: {
      roman: true,
      english: false,
      hindi: false,
      shahmukhi: false
    }
  },
  ggs_audio: {
    audio1: false,
    audio2: false,
    audio3: false,
    audio4: true,
}
};
const sgFormState = {  
    showEnglish: false,
    showPhonetic: false,
    showHindi: false,
};

const Routing = () => {
  const location = useLocation();
  const [form, setForm] = useState(initialFormState);
  const [sgForm, setSgForm] = useState(sgFormState);

useEffect(() => {
  const savedForm = localStorage.getItem('Preference');
  console.log('************',savedForm)
  if (!savedForm) {
    localStorage.setItem('Preference', JSON.stringify(form));
  }
  const sgForm = localStorage.getItem('sgPreference');
  console.log('************',sgForm)
  if (!sgForm) {
    localStorage.setItem('sgPreference', JSON.stringify(sgFormState));
  } 
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="dicover-more" element={<DiscoverMore />} />        
        <Route path="gurbani-search" element={<GurbaniSearch />} />
        <Route path="feedback" element={<Feedback />} />
        <Route path="site-map" element={<SiteMap />} />
        <Route path="preferences" element={<SitePreference />} />
        <Route path="unicode" element={<Unicode />} />

        <Route path="guru-granth-sahib/introduction" element={<GgsIntroduction />} />
        <Route path="guru-granth-sahib/ang-by-ang" element={<AngByAng />} />
        <Route path="guru-granth-sahib/ang/:page_no/line/:line_no" element={<AngLine />} />
        <Route path="guru-granth-sahib/ang/:page_no" element={<AngLine />} />
        <Route path="guru-granth-sahib/shabad/:shabad_id/line/:lineno" element={<ShabadLine />} />
        <Route path="guru-granth-sahib/verse/:page_no" element={<GGSVerse />} />
        <Route path="guru-granth-sahib/index/chapter" element={<SggsShabadIndex />} />
        <Route path="guru-granth-sahib/index/author" element={<AuthorIndex />} />
        <Route path="guru-granth-sahib/index/author/:slug" element={<AuthorRaga />} />
        <Route path="guru-granth-sahib/sggs-world-language" element={<SggsInWorldLanguage />} />
        <Route path="guru-granth-sahib/search" element={<GgsAdvancedSearch />} />
        <Route path="gurbani-searching" element={<GurbaniSearching />} />

        <Route path="amrit-keertan/introduction" element={<AkIntroduction />} />
        <Route path="amrit-keertan/page-by-page" element={<PageByPage />} />
        <Route path="amrit-keertan/page/:page_no/line/:line_no" element={<PageByPage />} />
        <Route path="amrit-keertan/index/chapter" element={<ChapterIndex />} />      
        <Route path="amrit-keertan/chapter/:chapter_no/:chapter_name" element={<ChapterName />} />   
        <Route path="amrit-keertan/index/english" element={<EnglishIndex />} />
        <Route path="amrit-keertan/index/hindi" element={<HindiIndex />} />
        <Route path="amrit-keertan/index/punjabi" element={<PunjabiIndex />} />
        <Route path="amrit-keertan/shabad/:shabad_id/line/:lineno" element={<AkShabadLine />} />
        <Route path="amrit-keertan/shabad/:shabad_id/:shabad_name" element={<AkShabadLine />} />
        <Route path="amrit-keertan/search" element={<AkAdvancedSearch />} />

        <Route path="bhai-gurdas-vaaran/introduction" element={<BgvIntroduction />} />
        <Route path="bhai-gurdas-vaaran/pauri-by-pauri" element={<PauriByPauri />} />
        <Route path="bhai-gurdas-vaaran/vaar/:vaar_no/pauri/:pauri_no/line/:line_no" element={<PauriByPauri />} />
        <Route path="bhai-gurdas-vaaran/index/vaar" element={<VaarIndex />} />
        <Route path="bhai-gurdas-vaaran/vaar-index/:vaar_no" element={<VaarIndex />} />
        <Route path="bhai-gurdas-vaaran/search" element={<BgvAdvancedSearch />} />

        <Route path="dasam-granth/introduction" element={<DgsIntroduction />} />  
        <Route path="dasam-granth/page-by-page" element={<DGPageByPage />} />  
        <Route path="dasam-granth/page/:page_no" element={<PageLine />} />  
        <Route path="dasam-granth/shabad/:shabad_id/line/:lineno" element={<DgShabadLine />} />
        <Route path="dasam-granth/page/:page_no/line/:line_no" element={<PageLine />} />
        <Route path="dasam-granth/verse/:page_no" element={<DgVerse />} />
        <Route path="dasam-granth/index/chapter/pb" element={<DgChapterPb />} /> 
        <Route path="dasam-granth/index/chapter/en" element={<DgChapterEn />} /> 
        <Route path="dasam-granth/search" element={<DgsAdvancedSearch />} />

        <Route path="kabit-savaiye/kabit-by-kabit" element={<KabitByKabit />} /> 
        <Route path="kabit-savaiye/search" element={<KsAdvancedSearch />} /> 
        
        <Route path="bhai-nand-lal/ghazal" element={<Ghazalas />} />  
        <Route path="bhai-nand-lal/quatrains" element={<Rubaayee />} />
        <Route path="bhai-nand-lal/zindginama" element={<Zindginama />} />
        <Route path="bhai-nand-lal/ganjnama" element={<Ganjnama />} />
        <Route path="bhai-nand-lal/jot-bikas" element={<JotBikasPb />} />
        <Route path="bhai-nand-lal/jot-bikas-persian" element={<JotBikasPersian />} />
        <Route path="bhai-nand-lal/rahitnama" element={<Rahitnama />} />
        <Route path="bhai-nand-lal/tankahnama" element={<Tankahnama />} />
        <Route path="bhai-nand-lal/:type/shabad/:shabad_id/line/:lineno" element={<BnlShabadLine />} />
        <Route path="bhai-nand-lal/:type/page/:page_no/line/:lineno" element={<BnlPageLine />} />
        <Route path="bhai-nand-lal/:type/verse/:page_no" element={<BnlVerse />} />
        <Route path="bhai-nand-lal/search" element={<BnlAdvancedSearch />} />
        
        <Route path="sundar-gutka" element={<SundarGutka />} /> 
        <Route path="baanis/japji-sahib" element={<JapjiSahib />} /> 
        <Route path="baanis/jaap-sahib" element={<JaapSahib />} />
        <Route path="baanis/tvai-prasadh-savaiye" element={<TvaiPrasadh />} />
        <Route path="baanis/chaupai-sahib" element={<ChaupaiSahib />} />
        <Route path="baanis/anand-sahib" element={<AnandSahib />} />
        <Route path="baanis/rehraas-sahib" element={<RehraasSahib />} />
        <Route path="baanis/kirtan-sohila" element={<KirtanSohila />} />

        <Route path="baanis/anand-sahib-bhog" element={<AnandBhog />} />
        <Route path="baanis/aarti" element={<Aarti />} />
        <Route path="baanis/laavan-anand-karaj" element={<Laavan />} />
        <Route path="baanis/asa-ki-vaar" element={<AsaKiVaar />} />
        <Route path="baanis/sukhmani-sahib" element={<SukhmaniSahib />} />
        <Route path="baanis/sidh-gosht" element={<SidhGosht />} />
        <Route path="baanis/ramkali-sadh" element={<RamkaliSadh />} />
        <Route path="baanis/dhakanee-oankaar" element={<DhakaneeOankaar />} />
        <Route path="baanis/baavan-akhree" element={<Baavan />} />
        <Route path="baanis/shabad-hazare" element={<GGSShabadHazare />} />
        <Route path="baanis/baarah-maaha" element={<BaarahMaaha />} />
        <Route path="baanis/sukhmana-sahib" element={<SukhmanaSahib />} />
        <Route path="baanis/dukh-bhanjani-sahib" element={<DukhBhanjani />} />
        <Route path="baanis/salok-sehskritee" element={<Salok />} />
        <Route path="baanis/gathaa" element={<Gathaa />} />
        <Route path="baanis/phunhay-m5" element={<Phunhay />} />
        <Route path="baanis/chaubolay-m5" element={<Chaubolay />} />
        <Route path="baanis/salok-kabeer-ji" element={<SalokKabeer />} />
        <Route path="baanis/salok-farid-ji" element={<SalokFarid />} />
        <Route path="baanis/savaiye-m1" element={<SavaiyeM1 />} />
        <Route path="baanis/savaiye-m2" element={<SavaiyeM2 />} />
        <Route path="baanis/savaiye-m3" element={<SavaiyeM3 />} />
        <Route path="baanis/savaiye-m4" element={<SavaiyeM4 />} />
        <Route path="baanis/savaiye-m5" element={<SavaiyeM5 />} />
        <Route path="baanis/salok-m9" element={<SalokM9 />} />

        <Route path="baanis/akal-ustati" element={<AkalUstati />} />
        <Route path="baanis/bachitar-natak" element={<BachitarNatak />} />
        <Route path="baanis/shabad-hazare-paatishahi-10" element={<ShabadHazare />} />
        <Route path="baanis/tvai-prasaadh-savaiye-dheenan-kee" element={<DGTvai />} />

        <Route path="hukumnama" element={<HukumIndex />} />
        <Route path="hukumnama/ang/:ang" element={<HukumAng />} />  
        <Route path="hukum" element={<SriDarbarSahib />} />  
        <Route path="hukumnama/cyber" element={<CyberHukumnama />} />  

        <Route path="sgdv/isg" element={<ISearchGurbani />} />  
        <Route path="sgdv" element={<SearchGurbaniDV/>} />

        <Route path="mahan-kosh" element={<Mahankosh />} /> 
        <Route path="mahan-kosh/view" element={<MahankoshView />} />
        <Route path="guru-granth-sahib/search-results" element={<SearchResultPreview />} />

        <Route path="guru-granth-kosh" element={<SGGKSearch />} />
        <Route path="guru-granth-kosh/view" element={<SGGKView />} /> 

        <Route path="sggs-kosh" element={<SggskSearch/>} />
        <Route path="sggs-kosh/view" element={<SggskView/>} />

        <Route path="maansarovar" element={<Maansarovar/>} />
        <Route path="maansarovar/words" element={<MaansarovarView/>} />
        <Route path="maansarovar/quotations/:word" element={<MaansarovarIndex/>} />

        <Route path="sri-nanak-prakash" element={<SNPrakash/>} />
        <Route path="sri-nanak-prakash/search-preview" element={< SNPSearchView/>} />
        <Route path="sri-nanak-prakash/chapters/:chapterId" element={< SNPrakashSectn/>} />
        <Route path="sri-nanak-prakash/page/:pageno/volume/:volumeno" element={< SNPrakashView/>} />
        <Route path="sri-nanak-prakash/page" element={< SNPrakashView/>} />

        <Route path="sri-gur-pratap-suraj-granth" element={<SGPSGSearch/>} />
        <Route path="sri-gur-pratap-suraj-granth/search-preview" element={< SgpsgSearchView/>} />
        <Route path="sri-gur-pratap-suraj-granth/volumes" element={<SGPSGVolume/>} />
        <Route path="sri-gur-pratap-suraj-granth/chapters/:volume_id/:volume_name" element={<SGPSGIndex/>} />
        <Route path="sri-gur-pratap-suraj-granth/chapters/:volume_id" element={<SGPSGIndex/>} />
        <Route path="sri-gur-pratap-suraj-granth/page/:pageno/volume/:volumeno" element={<SGPSGView/>} />
        <Route path="sri-gur-pratap-suraj-granth/page" element={<SGPSGView/>} />
        
        <Route path="faridkot-wala-teeka" element={<FWTSearch/>} />
        <Route path="faridkot-wala-teeka/search-preview" element={< FWTSearchView/>} />
        <Route path="faridkot-wala-teeka/chapters" element={<FWTIndex/>} />
        <Route path="faridkot-wala-teeka/page/:pageno/volume/:volumeno" element={<FWTView/>} />
        <Route path="faridkot-wala-teeka/page" element={<FWTView/>} />

        <Route path="sri-guru-granth-darpan" element={<SGGDSearch/>} />
        <Route path="sri-guru-granth-darpan/search-preview" element={< SGGDSeachView/>} />
        <Route path="sri-guru-granth-darpan/page" element={<SGGDView/>} />

        <Route path="gurus" element={<Sahiban/>} />
        <Route path="gurus/gurunanak" element={<GuruNanak/>} />
        <Route path="gurus/gurunanak2" element={<GuruNanak2/>} />
        <Route path="gurus/gurunanak3" element={<GuruNanak3/>} />
        <Route path="gurus/gurunanak4" element={<GuruNanak4/>} />
        <Route path="gurus/gurunanak5" element={<GuruNanak5/>} />
        <Route path="gurus/gurunanak6" element={<GuruNanak6/>} />
        <Route path="gurus/gurunanak7" element={<GuruNanak7/>} />
        <Route path="gurus/gurunanak9" element={<GuruNanak9/>} />
        <Route path="gurus/gurunanak10" element={<GuruNanak10/>} />
        <Route path="gurus/guruangad" element={<GuruAngad/>} />

        <Route path="bhatts" element={<BhattSahiban/>} />
        <Route path="bhatts/bhatt-kalshar" element={<Kalshar/>} />
        <Route path="bhatts/bhatt-salh" element={<Salh/>} />
        <Route path="bhatts/bhatt-mathura" element={<Mathura/>} />
        <Route path="bhatts/satta" element={<SattaBalwand/>} />
        <Route path="bhatts/bhatt-jalap" element={<Jalap/>} />
        <Route path="bhatts/bhatt-balh" element={<Balh/>} />
        <Route path="bhatts/bhatt-bhalh" element={<Bhalh/>} />
        <Route path="bhatts/mardana" element={<Mardana/>} />
        <Route path="bhatts/bhatt-kirat" element={<Kirat/>} />
        <Route path="bhatts/bhatt-nalh" element={<Nalh/>} />
        <Route path="bhatts/bhatt-haribans" element={<Haribans/>} />
        <Route path="bhatts/bhatt-bhika" element={<Bhika/>} />
        <Route path="bhatts/bhatt-gyand" element={<Gyand/>} />
        <Route path="bhatts/sunderbaba" element={<BabaSundar/>} />

        <Route path="raags" element={<GurbaniRaags />} />
        <Route path="raags/raag-siri" element={<SriRaag />} />
        <Route path="raags/raags_time" element={<Timing />} />
        <Route path="taal" element={<Taal />} />
        <Route path="raags/glossary" element={<Golssary />} />
        <Route path="saaj" element={<Muscical />} />

        <Route path="music/page/:pageno" element={<IndianClassical />} />

        
      </Route> 
      <Route path="guru-granth-sahib/ang/:page_no/print-view" element={<AngPrint />} /> 
      <Route path="guru-granth-sahib/verse/:page_no/print" element={<VersePrintView />} /> 
      <Route path="amrit-keertan/page/:page_no/print-view" element={<AkPagePrint />} /> 
      <Route path="bhai-gurdas-vaaran/vaar/:vaar_no/pauri/:pauri_no/print-view" element={<PrintPauri />} /> 
      <Route path="dasam-granth/page/:page_no/print-view" element={<DgPagePrint />} /> 
      <Route path="kabit-savaiye/kabit/:page_no/print-view" element={<KsPagePrint />} /> 
      
      <Route path="bhai-nand-lal/:type/page/:page_no/print-view" element={<BnlPrintPage />} />
      <Route path="guru-granth-sahib/shabad/:shabad_id/line/:lineno/print-view" element={<GgsShabadPrint />} />
      <Route path="amrit-keertan/shabad/:shabad_id/line/:lineno/print-view" element={<AkShabadPrint />} />
      <Route path="dasam-granth/shabad/:shabad_id/line/:lineno/print-view" element={<DgShabadPrint />} />
      <Route path="bhai-nand-lal/:type/:shabad_id/line/:lineno/:title/print-view" element={<BnlShabadPrint />} />
      <Route path="guru-granth-sahib/verse/:page_no/print-view" element={<GgsVersePrint />} />
      <Route path="dasam-granth/verse/:page_no/print-view" element={<DgVersePrint />} />
      <Route path="bhai-nand-lal/:type/verse/:page_no/print-view" element={<BnlVersePrint />} />
      <Route path="baanis/:type/page/:page_no/print-view" element={<SgPrintPage />} />
      
    </Routes>

  )
}
export default Routing