//Gurbani search//
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useOutletContext } from "react-router";
import { Link, useLocation, useParams} from "react-router-dom";
import 'react-h5-audio-player/lib/styles.css';
import DgChapterIndex from '../../components/DgChapterIndex';


function DgChapterPb() {
    const { page_no, line_no } = useParams();
    const [isNos, setIsNos] = useState(false);
    return (
        <div>
            <DgChapterIndex lang="pb"/>
        </div>
    )
}

export default DgChapterPb