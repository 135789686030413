import React  from 'react';
import 'react-h5-audio-player/lib/styles.css';
import BnlPages from '../../components/BnlPages';


function Ghazalas() {
    return (
        <div>
            <BnlPages title="Bhai Nand Lal -Divan-e-Goya: Ghazals" apiName="ghazal/" tlPage="63" /> 
        </div>
    )
}

export default Ghazalas