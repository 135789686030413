import React, { useState } from "react";
import Header from "../components/Header";
import { Outlet } from "react-router";
import Footer from "../components/Footer";
//import '../../../../index.css'


const Layout = (props) => {
   
    return (
        <div >
          <div>
            <Header/>
            <Outlet/>
            <Footer/>
          </div>
      </div>
    )
}
export default Layout;