import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function RehraasSahib() {
    return (
        <div>
            <SGCommonPage title="Rehraas Sahib" apiName="rehraas-sahib" audioName="rehraas_sahib" tlPage="15" /> 
        </div>
    )
}

export default RehraasSahib