import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function DhakaneeOankaar() {
    return (
        <div>
            <SGCommonPage title="Dhakanee Oankaar" apiName="dhakanee-oankaar" audioName="dhakanee_oankaar" tlPage="16" /> 
        </div>
    )
}

export default DhakaneeOankaar