import React, { useEffect, useState } from 'react';
import Spinner from '../../../components/Spinner';
import '../../../assets/css/dashboard.css';
import '../../../assets/css/intro.css';
import '../../../assets/css/ang-by-ang.css';
import '../../../assets/css/style.css';
import '../../../assets/css/indian-class.css';
import { ApiHelper } from '../../../helpers/ApiHelper';
import { API } from '../../../config/api';
import  Axios  from 'axios';

function IndianClassical() {
    const [introData, setIntroData] = useState([]);
    const [error, setError] = useState(false)
    const [loader, setLoader] = useState(false);
    const [angNo, setAngNo] = useState("1");

    useEffect(() => {
        getMusic()
    }, [])
    const getMusic = async () => {
        setLoader(true)
        await Axios.get(API.getMusicIndex + '?page=' + "1" + '&volume_id=0&lang=' )
            .then((resData) => {
                setLoader(false)
                console.log('get Music', resData.data.lines);
                setIntroData(resData.data.lines)
                
            })
            .catch((err) => {
                setLoader(false)
                console.log(err);
            })
    }
    return (
        <div>
            {loader && <Spinner />}
            <section className='inner-actions p-4' >
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 p-0'>
                            <div className='in-act-wrapper'>
                                <h1 className='inner-heading-music' >Indian Classical Music and Sikh Kirtan</h1>
                                <div className='actions-mains'>
                                    <button className='action-btn-main' ><i class="bi bi-printer"></i></button>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12 d-flex-justify-content-end ang-ang  mt-3'>
                            <div className='audio-features'>
                                <div className='toggle-buttons-inner'>
                                    <label className='me-2 go-name' >Goto Page </label>
                                    <label className='switch'>
                                        {/* <input type='checkbox'></input>
                                    <span className='slider'></span> */}
                                        <div className='go-to-ang position-relative'>
                                            <div className='form-group'>
                                                <input type='text' placeholder='1' className='form-control'
                                                ></input>
                                                <button className='ang-btn'>Go</button>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div className='go-line-wrapper' >
                                {/*<div className='go-to-ang position-relative'>
                                <div className='form-group'>
                                    <input type='text' placeholder='go to ang' className='form-control'
                                       ></input>
                                    <button className='ang-btn'>Go</button>
                                </div>
                            </div>*/}
                                <div className='navigation-btn-audio'>

                                    <button className='ang-btn' style={{ background: 'var(--current-color, var(--color-1))', color: '#fff' }}>Book Index</button>
                                    <button className='ang-btn' style={{ background: 'var(--current-color, var(--color-1))', color: '#fff' }} >Next</button>
                                    <button className='ang-btn' style={{ background: 'var(--current-color, var(--color-1))', color: '#fff' }} >Last</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className='inner-actions p-4' >
                <div className='container index-wrp'>
                {introData ?
                                        < div
                                        dangerouslySetInnerHTML={{
                                            __html: introData[0]?.text
                                            }} 
                                        /> : null}
                                </div>
                    {/* <div className='row'>
                        <p className='top-blue'>Indian Classical Music And Sikh Kirtan
                            by Gobind Singh Mansukhani (M.A., LL.B, Ph.D.) © 1982</p>
                        <div className='d-flex flex-column'>
                            <div className='ang-display mt-5'>
                                <h1>Displaying Page 1 of 100</h1>
                                <div className='ang-wrapper'>
                                    <div className='ang-itm'>
                                        <ul>

                                            <li><a href=''><span class="label">Forward - Preface - Introduction - About the Author.................</span>5</a></li>

                                            <li><a href=''><span class="label">Forward - Preface - Introduction - About the Author.................</span>5</a></li>

                                            <li><a href=''><span class="label">Forward - Preface - Introduction - About the Author.................</span>5</a></li>


                                        </ul>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
            </section>



        </div>
    )
}

export default IndianClassical