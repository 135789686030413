import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function DukhBhanjani() {
    return (
        <div>
            <SGCommonPage title="Dukh Bhanjani Sahib" apiName="dukh-bhanjani-sahib" audioName="dukh_bhanjani_sahib" tlPage="12" /> 
        </div>
    )
}

export default DukhBhanjani