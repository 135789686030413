import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';
import '../assets/css/header.css';
import Logo from '../assets/icons/logo.svg';
import ShareIcon from '../assets/icons/share.svg';
import facebook from '../assets/img/facebook.svg';
import twitter from '../assets/img/twitter.svg';
import youtube from '../assets/img/youtube.svg';
import telegram from '../assets/img/telegram.svg';
import whatsapp from '../assets/img/whatsapp.svg';
import mail from '../assets/img/mail.svg';
import logot from '../assets/img/logoT.svg';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ListGroup from 'react-bootstrap/ListGroup';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Axios from 'axios';
import { API } from "../config/api";
import { ApiHelper } from '../helpers/ApiHelper';
import Validation from './Validation';

function Header(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const shareUrl = 'http://localhost:3000';
  const title = 'Search Gurbani : Gurbani Website';
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [pError, setPError] = useState(false);
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  /* useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case '/':
        document.documentElement.style.setProperty('--current-color', 'var(--color-1)');
        break;
      case '/gurbani-search':
        document.documentElement.style.setProperty('--current-color', 'var(--color-2)');
        break;
      case '/guru-granth-sahib/introduction':
      case '/guru-granth-sahib/ang-by-ang':
      case '/guru-granth-sahib/index/chapter':
      case '/guru-granth-sahib/index/author':
      case '/guru-granth-sahib/sggs-world-language':
      case '/guru-granth-sahib/search':
        document.documentElement.style.setProperty('--current-color', 'var(--color-3)');
        document.documentElement.style.setProperty('--current-color-dim', 'var( --color-3L)');
        break;
      case '/amrit-keertan/introduction':
      case '/amrit-keertan/page-by-page':
      case '/amrit-keertan/index/chapter':
      case '/amrit-keertan/index/english':
      case '/amrit-keertan/index/hindi':
      case '/amrit-keertan/index/punjabi':
      case '/amrit-keertan/search':
        document.documentElement.style.setProperty('--current-color', 'var(--color-4)');
        document.documentElement.style.setProperty('--current-color-dim', 'var( --color-4L)');
        break;
      case '/bhai-gurdas-vaaran/introduction':
      case '/bhai-gurdas-vaaran/pauri-by-pauri':
      case '/bhai-gurdas-vaaran/index/vaar':
      case '/bhai-gurdas-vaaran/search':
        document.documentElement.style.setProperty('--current-color', 'var(--color-5)');
        document.documentElement.style.setProperty('--current-color-dim', 'var( --color-5L)');
        break;
      case '/dasam-granth/introduction':
      case '/dasam-granth/page-by-page':
      case '/dasam-granth/index/chapter/pb':
      case '/dasam-granth/index/chapter/en':
      case '/dasam-granth/search':
        document.documentElement.style.setProperty('--current-color', 'var(--color-6)');
        document.documentElement.style.setProperty('--current-color-dim', 'var( --color-6L)');
        break;
      case '/kabit-savaiye/kabit-by-kabit':
      case '/kabit-savaiye/search':
        document.documentElement.style.setProperty('--current-color', 'var(--color-7)');
        document.documentElement.style.setProperty('--current-color-dim', 'var( --color-7L)');
        break;
      case '/bhai-nand-lal/ghazal':
      case '/bhai-nand-lal/quatrains':
      case '/bhai-nand-lal/zindginama':
      case '/bhai-nand-lal/ganjnama':
      case '/bhai-nand-lal/jot-bikas':
      case '/bhai-nand-lal/jot-bikas-persian':
      case '/bhai-nand-lal/rahitnama':
      case '/bhai-nand-lal/tankahnama':
      case '/bhai-nand-lal/search':
        document.documentElement.style.setProperty('--current-color', 'var(--color-8)');
        document.documentElement.style.setProperty('--current-color-dim', 'var( --color-8L)');
        break;
      case '/baanis/japji-sahib':
      case '/baanis/jaap-sahib':
      case '/baanis/tvai-prasadh-savaiye':
      case '/baanis/chaupai-sahib':
      case '/baanis/anand-sahib':
      case '/baanis/rehraas-sahib':
      case '/baanis/kirtan-sohila':
      case '/baanis/akal-ustati':
      case '/baanis/bachitar-natak':
      case '/baanis/shabad-hazare-paatishahi-10':
      case '/baanis/tvai-prasaadh-savaiye-dheenan-kee':
      case '/baanis/anand-sahib-bhog':
      case '/baanis/aarti':
      case '/baanis/laavan-anand-karaj':
      case '/baanis/asa-ki-vaar':
      case '/baanis/sukhmani-sahib':
      case '/baanis/sidh-gosht':
      case '/baanis/ramkali-sadh':
      case '/baanis/dhakanee-oankaar':
      case '/baanis/baavan-akhree':
      case '/baanis/shabad-hazare':
      case '/baanis/baarah-maaha':
      case '/baanis/sukhmana-sahib':
      case '/baanis/dukh-bhanjani-sahib':
      case '/baanis/salok-sehskritee':
      case '/baanis/gathaa':
      case '/baanis/phunhay-m5':
      case '/baanis/chaubolay-m5':
      case '/baanis/salok-kabeer-ji':
      case '/baanis/salok-farid-ji':
      case '/baanis/savaiye-m1':
      case '/baanis/savaiye-m2':
      case '/baanis/savaiye-m3':
      case '/baanis/savaiye-m4':
      case '/baanis/savaiye-m5':
      case '/baanis/salok-m9':
      case '/sundar-gutka':
        document.documentElement.style.setProperty('--current-color', 'var(--color-9)');
        document.documentElement.style.setProperty('--current-color-dim', 'var( --color-9L)');
        break;
      case '/sgdv/isg':
      case '/sgdv':
      case '/mahan-kosh':
      case '/guru-granth-kosh':
      case '/sggs-kosh':
      case '/maansarovar':
      case '/sri-nanak-prakash':
      case '/sri-gur-pratap-suraj-granth':
      case '/faridkot-wala-teeka':
      case '/raags':
        document.documentElement.style.setProperty('--current-color', 'var(--colo-x)');
        document.documentElement.style.setProperty('--current-color-dim', 'var( --colo-xL)');
        break;
      default:
        document.documentElement.style.setProperty('--current-color', 'var(--color-common)');
        document.documentElement.style.setProperty('--current-color-dim', 'var(--color-commonL)');
        break;
    }
  }, [location.pathname]); */
  useEffect(() => {
    const path = location.pathname;

    const setColor = (color, colorDim) => {
      document.documentElement.style.setProperty('--current-color', `var(${color})`);
      document.documentElement.style.setProperty('--current-color-dim', `var(${colorDim})`);
    };

    if (path === '/') {
      setColor('--color-1', '--color-1L');
    } else if (path.startsWith('/gurbani-search')) {
      setColor('--color-2', '--color-2L');
    } else if (path.startsWith('/guru-granth-sahib')) {
      setColor('--color-3', '--color-3L');
    } else if (path.startsWith('/amrit-keertan')) {
      setColor('--color-4', '--color-4L');
    } else if (path.startsWith('/bhai-gurdas-vaaran')) {
      setColor('--color-5', '--color-5L');
    } else if (path.startsWith('/dasam-granth')) {
      setColor('--color-6', '--color-6L');
    } else if (path.startsWith('/kabit-savaiye')) {
      setColor('--color-7', '--color-7L');
    } else if (path.startsWith('/bhai-nand-lal')) {
      setColor('--color-8', '--color-8L');
    } else if (path.startsWith('/baanis')) {
      setColor('--color-9', '--color-9L');
    } else {
      setColor('--color-common', '--color-commonL');
    }
  }, [location.pathname]);

  //mobile menu Show
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isRegister, setIsRegister] = useState(false);
  const handleRegisterClose = () => setIsRegister(false);
  const handleRegistershow = () => setIsRegister(true);

  const [isLogin, setIsLogin] = useState(false);
  const handleLoginClose = () => setIsLogin(false);
  const handleLoginShow = () => setIsLogin(true);

  const [fix, setFix] = useState(false)

  function setFixed() {
    if (window.scrollY >= 160) {
      setFix(true)
    }
    else {
      setFix(false)
    }
  }
  window.addEventListener("scroll", setFixed)

  const [share, setShare] = useState(false);
  const handleShareClose = () => setShare(false);
  const handleShare = () => setShare(true);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const handleShareModal = (platform) => {
    let shareLink = '';

    switch (platform) {
      case 'facebook':
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl + location.pathname)}&t=${encodeURIComponent(title)}`;
        break;
      case 'twitter':
        shareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl + location.pathname)}&text=${encodeURIComponent(title)}`;
        break;
      case 'youtube':
        // YouTube does not have a direct sharing link for a URL, this is just an example
        shareLink = `https://www.youtube.com`;
        break;
      case 'telegram':
        shareLink = `https://telegram.me/share/url?url=${encodeURIComponent(shareUrl + location.pathname)}&text=${encodeURIComponent(title)}`;
        break;
      case 'whatsapp':
        shareLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(title + " " + shareUrl + location.pathname)}`;
        break;
      case 'mail':
        shareLink = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(shareUrl + location.pathname)}`;
        break;
      default:
        break;
    }

    window.open(shareLink, '_blank');
    handleShareClose();

  };
  const handleEmail = (event) => {
    const value = event.target.value;
    const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    const result = pattern.test(value);
    if (result === false) {
      setEmailError(true)
    }
  }
  const handleRegister = async () => {
    if (firstname !== '' && lastname !== '' && email !== '' && password !== '' && confirmPassword !== '') {
     if(password === confirmPassword){
      setError(false)
      setLoader(true)
      const params = new FormData();
      params.append('firstName', firstname);
      params.append('lastName', lastname);
      params.append('email', email);
      params.append('password', password);
      params.append('confirmed', confirmPassword);

      console.log('para', params)
      await Axios.post(API.register, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((resData) => {
          setLoader(false)
          console.log('register', resData);
          if (resData.data.firstName !== "") {
            alert('Registered Successfully')
          }
          setFirstName('');
          setLastName('');
          setEmail('');
          setPassword('');
          setConfirmPassword('');
        })
        .catch((err) => {
          setLoader(false)
          console.log(err);
        })
     }
     else{
      setPError(true)
     }
    }
    else {
      /* alert('Plese fill mandatory fields'); */
      setLoader(false)
      setError(true)
    }

  }
  const handleLogin = async () => {
    if (email !== '' && password !== '') {
      setError(false)
      setLoader(true)
      const params = new FormData();
      params.append('email', email);
      params.append('password', password);

      console.log('para', params)
      await Axios.post(API.login, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((resData) => {
          setLoader(false)
          console.log('register', resData);
          if (resData.data.status === true) {
            alert('Login Successfully')
          }
          setEmail('');
          setPassword('');
        })
        .catch((err) => {
          setLoader(false)
          console.log(err);
        })
    }
    else {
      setError(true)
      setLoader(false)
    }

  }

  return (
    <header className={fix ? 'header fixed' : 'header'} >
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand as={Link} to="/"> <img src={Logo} className="img-fluid" alt="Responsive image" /></Navbar.Brand>
          <div className='moobile-hd-btns only-mobile' >
            <button onClick={handleShare} className='share'><i className="bi bi-share"></i></button>
          </div>
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <LinkContainer to="/"><Nav.Link>Home</Nav.Link></LinkContainer>
              <LinkContainer to="/preferences"><Nav.Link>Site Preferences</Nav.Link></LinkContainer>
              <LinkContainer to="/feedback"><Nav.Link>Feedback</Nav.Link></LinkContainer>
              <LinkContainer to="/site-map"><Nav.Link>Sitemap</Nav.Link></LinkContainer>
              <LinkContainer to="/hukum"><Nav.Link>Today's Hukumnama</Nav.Link></LinkContainer>
            </Nav>
            <button className='register'
              onClick={handleRegistershow}>Register</button>
            <button className='login' onClick={handleLoginShow}>Login</button>
            <button onClick={handleShare} className='share'><i className="bi bi-share"></i></button>
          </Navbar.Collapse>

          <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <ul className='d-flex sub-menu-wrapper' >
                <li>
                  <div className='d-flex side-reg'>
                    <button className='register' onClick={handleRegistershow}>Register</button>
                    <button className='login' onClick={handleLoginShow}>Login</button>
                  </div>
                </li>
                <li> <LinkContainer to="/"><Nav.Link>Home</Nav.Link></LinkContainer> </li>
                <li> <LinkContainer to="/gurbani-search"><Nav.Link>Gurbani Search</Nav.Link></LinkContainer> </li>
                {/* <li> <Nav.Link href="/gurbani-search">Gurbani Search</Nav.Link></li> */}
                <li>
                  <NavDropdown title="Guru Granth Sahib" id="navbarScrollingDropdown" >
                    <LinkContainer to="/guru-granth-sahib/introduction"><NavDropdown.Item>Introduction</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/guru-granth-sahib/ang-by-ang"><NavDropdown.Item>Ang by Ang</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/guru-granth-sahib/index/chapter"><NavDropdown.Item>SGGS Shabad Index</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/guru-granth-sahib/index/author"><NavDropdown.Item>Author Index</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/guru-granth-sahib/sggs-world-language"><NavDropdown.Item>SGGS in World Languages</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/guru-granth-sahib/search"><NavDropdown.Item>Search Guru Granth Sahib</NavDropdown.Item></LinkContainer>
                  </NavDropdown>
                </li>
                <li>
                  <NavDropdown title="Amrit Keertan" id="navbarScrollingDropdown">
                    <LinkContainer to="/amrit-keertan/introduction"><NavDropdown.Item>Introduction</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/amrit-keertan/page-by-page"><NavDropdown.Item>Page by Page</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/amrit-keertan/index/chapter"><NavDropdown.Item>Chapter Index</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/amrit-keertan/index/english"><NavDropdown.Item>English Index</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/amrit-keertan/index/punjabi"><NavDropdown.Item>Punjabi Index</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/amrit-keertan/index/hindi"><NavDropdown.Item>Hindi Index</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/amrit-keertan/search"><NavDropdown.Item>Search Amrit Keertan</NavDropdown.Item></LinkContainer>
                  </NavDropdown>
                </li>
                <li>
                  <NavDropdown title="Bhai Gurdas Vaaran" id="navbarScrollingDropdown">
                    <LinkContainer to="/bhai-gurdas-vaaran/introduction"><NavDropdown.Item>Introduction</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/bhai-gurdas-vaaran/pauri-by-pauri"><NavDropdown.Item>Pauri by Pauri</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/bhai-gurdas-vaaran/index/vaar"><NavDropdown.Item>Vaar Index</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/bhai-gurdas-vaaran/search"><NavDropdown.Item>Search Gurdas Vaaran </NavDropdown.Item></LinkContainer>
                  </NavDropdown>
                </li>
                <li>
                  <NavDropdown title="Dasam Granth Sahibe" id="navbarScrollingDropdown">
                    <LinkContainer to="/dasam-granth/introduction"><NavDropdown.Item>Introduction</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/dasam-granth/page-by-page"><NavDropdown.Item>Page by Page</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/dasam-granth/index/chapter/pb"><NavDropdown.Item>Chapter Index Punjabi</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/dasam-granth/index/chapter/en"><NavDropdown.Item>Chapter Index English</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/dasam-granth/search"><NavDropdown.Item>Search Dasam Granth</NavDropdown.Item></LinkContainer>
                  </NavDropdown>
                </li>
                <li>
                  <NavDropdown title="Kabit Savaiye" id="navbarScrollingDropdown">
                    <LinkContainer to="/kabit-savaiye/kabit-by-kabit"><NavDropdown.Item>Kabit by Kabit</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/kabit-savaiye/search"><NavDropdown.Item>Search Kabit Savaiye</NavDropdown.Item></LinkContainer>
                  </NavDropdown>
                </li>
                <li>
                  <NavDropdown title="Bhai Nand Lal" id="navbarScrollingDropdown">
                    <LinkContainer to="/bhai-nand-lal/ghazal"><NavDropdown.Item>Divan-e-Goya : Ghazals</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/bhai-nand-lal/quatrains"><NavDropdown.Item>Rubaayee</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/bhai-nand-lal/zindginama"><NavDropdown.Item>Zindginama</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/bhai-nand-lal/ganjnama"><NavDropdown.Item>Ganjnama</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/bhai-nand-lal/jot-bikas"><NavDropdown.Item>Jot Bikas(Punjabi)</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/bhai-nand-lal/jot-bikas-persian"><NavDropdown.Item>Jot Bikas(Persian)</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/bhai-nand-lal/rahitnama"><NavDropdown.Item>Rahit Nama</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/bhai-nand-lal/tankahnama"><NavDropdown.Item>Tankah Nama</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/bhai-nand-lal/search"><NavDropdown.Item>Search Bhai Nand Lal</NavDropdown.Item></LinkContainer>
                  </NavDropdown>
                </li>
                <li>
                  <NavDropdown title="Sundar Gutka" id="navbarScrollingDropdown">
                    <LinkContainer to="/sundar-gutka"><NavDropdown.Item>Introduction</NavDropdown.Item></LinkContainer>
                    <NavDropdown title="Nitnem" className='second-level' id="navbarScrollingDropdown" >
                      <LinkContainer to="/baanis/japji-sahib"><NavDropdown.Item>Japji Sahib</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/jaap-sahib"><NavDropdown.Item>Jaap Sahib</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/tvai-prasadh-savaiye"><NavDropdown.Item>Tvai Prasadh Savaiye</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/chaupai-sahib"><NavDropdown.Item>Chaupai Sahib</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/anand-sahib"><NavDropdown.Item>Anand Sahib</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/rehraas-sahib"><NavDropdown.Item>Rehraas Sahib</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/kirtan-sohila"><NavDropdown.Item>Kirtan Sohila</NavDropdown.Item></LinkContainer>
                    </NavDropdown>
                    <NavDropdown title="Guru Granth Sahib" className='second-level' id="navbarScrollingDropdown" >
                      <LinkContainer to="/baanis/anand-sahib-bhog"><NavDropdown.Item>Anand Sahib(Bhog)</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/aarti"><NavDropdown.Item>Arti</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/laavan-anand-karaj"><NavDropdown.Item>Laavan(Anand Karaj)</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/asa-ki-vaar"><NavDropdown.Item>Asa Ki VAar</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/sukhmani-sahib"><NavDropdown.Item>Sukhmani Sahib</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/sidh-gosht"><NavDropdown.Item>Sidh Gosht</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/ramkali-sadh"><NavDropdown.Item>Ramkali Sadh</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/dhakanee-oankaar"><NavDropdown.Item>Dhakanee Oankaar</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/baavan-akhree"><NavDropdown.Item>Baavan Akhree</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/shabad-hazare"><NavDropdown.Item>Shabad Hazare</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/baarah-maaha"><NavDropdown.Item>Baarah Maaha</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/sukhmana-sahib"><NavDropdown.Item>Sukhmana Sahib</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/dukh-bhanjani-sahib"><NavDropdown.Item>Dukh Bhanjani Sahib</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/salok-sehskritee"><NavDropdown.Item>Salok Sehskritee</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/gathaa"><NavDropdown.Item>Gathaa</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/phunhay-m5"><NavDropdown.Item>Phunhay M:5</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/chaubolay-m5"><NavDropdown.Item>Chaubolay M:5</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/salok-kabeer-ji"><NavDropdown.Item>Salok Kabeer ji</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/salok-farid-ji"><NavDropdown.Item>Salok Farid ji</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/savaiye-m1"><NavDropdown.Item>Savaiye M:1</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/savaiye-m2"><NavDropdown.Item>Savaiye M:2</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/savaiye-m3"><NavDropdown.Item>Savaiye M:3</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/savaiye-m4"><NavDropdown.Item>Savaiye M:4</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/savaiye-m5"><NavDropdown.Item>Savaiye M:5</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/salok-m9"><NavDropdown.Item>Salok M:9</NavDropdown.Item></LinkContainer>

                    </NavDropdown>
                    <NavDropdown title="Dasam granth" className='second-level' id="navbarScrollingDropdown" >
                      <LinkContainer to="/baanis/akal-ustati"><NavDropdown.Item>Akal Ustati</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/bachitar-natak"><NavDropdown.Item>Bachitar Natak</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/shabad-hazare-paatishahi-10"><NavDropdown.Item>Shabad Hazare Paatishahi 10</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/baanis/tvai-prasaadh-savaiye-dheenan-kee"><NavDropdown.Item>Tvai Prasaadh Savai'ye(Dheenan Kee)</NavDropdown.Item></LinkContainer>
                    </NavDropdown>
                  </NavDropdown>
                </li>
                <li>
                  <NavDropdown title="Resources" id="navbarScrollingDropdown">
                    <NavDropdown title="Hukumnama" className='second-level' id="navbarScrollingDropdown" >
                      <LinkContainer to="/hukumnama"><NavDropdown.Item>Hukumnama Index</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/hukum"><NavDropdown.Item>Sri Darbar Sahib</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/hukumnama/cyber"><NavDropdown.Item>Cyber Hukumnama </NavDropdown.Item></LinkContainer>
                    </NavDropdown>
                    <LinkContainer to="/sgdv/isg"><NavDropdown.Item>iSearchGurbani</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/sgdv"><NavDropdown.Item>Search Gurbani Desktop Version</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/mahan-kosh"><NavDropdown.Item>GurShabad Ratanakar Mahankosh</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/guru-granth-kosh"><NavDropdown.Item>Sri Guru Granth Kosh</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/sggs-kosh"><NavDropdown.Item>SGGS Kosh</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/sri-nanak-prakash"><NavDropdown.Item>Sri Nanak Prakash</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/sri-gur-pratap-suraj-granth"><NavDropdown.Item>Sri Gur Pratap Suraj Granth</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/faridkot-wala-teeka"><NavDropdown.Item>Faridkot Wala Teeka</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/sri-guru-granth-darpan"><NavDropdown.Item>Sri Guru Granth Darpan</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/maansarovar"><NavDropdown.Item>Maansarovar</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/gurus"><NavDropdown.Item>Guru Sahiban</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/gurus"><NavDropdown.Item>Bhagat Sahiban</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/bhatts"><NavDropdown.Item>Bhatt Sahiban</NavDropdown.Item></LinkContainer>
                    <LinkContainer to="/raags"><NavDropdown.Item>Gurbani Raags</NavDropdown.Item></LinkContainer>
                  </NavDropdown>
                </li>
                {/* <li> <Nav.Link href="#action1">Site Preferences</Nav.Link></li>
                <li> <Nav.Link href="#action1">Feedback</Nav.Link></li>
                <li> <Nav.Link href="#action1">Sitemap</Nav.Link></li>
                <li> <Nav.Link href="#action1">Today's Hukumnama</Nav.Link></li> */}
                <li><LinkContainer to="/preferences"><Nav.Link>Site Preferences</Nav.Link></LinkContainer></li>
                <li><LinkContainer to="/feedback"><Nav.Link>Feedback</Nav.Link></LinkContainer></li>
                <li><LinkContainer to="/site-map"><Nav.Link>Sitemap</Nav.Link></LinkContainer></li>
                <li><LinkContainer to="/hukum"><Nav.Link>Today's Hukumnama</Nav.Link></LinkContainer></li>

              </ul>
            </Offcanvas.Body>
          </Offcanvas>
        </Container>
      </Navbar>
      <section className='sub-menu' >
        <Container>
          <ul className='d-flex sub-menu-wrapper' >
            <li> <LinkContainer to="/"><Nav.Link>Home</Nav.Link></LinkContainer></li>
            <li> <LinkContainer to="/gurbani-search"><Nav.Link>Gurbani Search</Nav.Link></LinkContainer> </li>
            <li>
              <NavDropdown title="Guru Granth Sahib" id="navbarScrollingDropdown">
                <LinkContainer to="/guru-granth-sahib/introduction"><NavDropdown.Item>Introduction</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/guru-granth-sahib/ang-by-ang"><NavDropdown.Item>Ang by Ang</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/guru-granth-sahib/index/chapter"><NavDropdown.Item>SGGS Shabad Index</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/guru-granth-sahib/index/author"><NavDropdown.Item>Author Index</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/guru-granth-sahib/sggs-world-language"><NavDropdown.Item>SGGS in World Languages</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/guru-granth-sahib/search"><NavDropdown.Item>Search Guru Granth Sahib</NavDropdown.Item></LinkContainer>
                {/* <NavDropdown.Item href="/guru-granth-sahib/ang-by-ang">Ang by Ang</NavDropdown.Item>
                <NavDropdown.Item href="/guru-granth-sahib/index/chapter">SGGS Shabad Index</NavDropdown.Item>
                <NavDropdown.Item href="/guru-granth-sahib/index/author">Author Index</NavDropdown.Item>
                <NavDropdown.Item href="/guru-granth-sahib/sggs-world-language">SGGS in World Languages</NavDropdown.Item>
                <NavDropdown.Item href="/guru-granth-sahib/search">Search Guru Granth Sahib</NavDropdown.Item> */}
              </NavDropdown>
            </li>
            <li>
              <NavDropdown title="Amrit Keertan" id="navbarScrollingDropdown">
                <LinkContainer to="/amrit-keertan/introduction"><NavDropdown.Item>Introduction</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/amrit-keertan/page-by-page"><NavDropdown.Item>Page by Page</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/amrit-keertan/index/chapter"><NavDropdown.Item>Chapter Index</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/amrit-keertan/index/english"><NavDropdown.Item>English Index</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/amrit-keertan/index/punjabi"><NavDropdown.Item>Punjabi Index</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/amrit-keertan/index/hindi"><NavDropdown.Item>Hindi Index</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/amrit-keertan/search"><NavDropdown.Item>Search Amrit Keertan</NavDropdown.Item></LinkContainer>
              </NavDropdown>
            </li>
            <li>
              <NavDropdown title="Bhai Gurdas Vaaran" id="navbarScrollingDropdown">
                <LinkContainer to="/bhai-gurdas-vaaran/introduction"><NavDropdown.Item>Introduction</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/bhai-gurdas-vaaran/pauri-by-pauri"><NavDropdown.Item>Pauri by Pauri</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/bhai-gurdas-vaaran/index/vaar"><NavDropdown.Item>Vaar Index</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/bhai-gurdas-vaaran/search"><NavDropdown.Item>Search Gurdas Vaaran </NavDropdown.Item></LinkContainer>
              </NavDropdown>
            </li>
            <li>
              <NavDropdown title="Dasam Granth Sahibe" id="navbarScrollingDropdown">
                <LinkContainer to="/dasam-granth/introduction"><NavDropdown.Item>Introduction</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/dasam-granth/page-by-page"><NavDropdown.Item>Page by Page</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/dasam-granth/index/chapter/pb"><NavDropdown.Item>Chapter Index Punjabi</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/dasam-granth/index/chapter/en"><NavDropdown.Item>Chapter Index English</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/dasam-granth/search"><NavDropdown.Item>Search Dasam Granth</NavDropdown.Item></LinkContainer>
              </NavDropdown>
            </li>
            <li>
              <NavDropdown title="Kabit Savaiye" id="navbarScrollingDropdown">
                <LinkContainer to="/kabit-savaiye/kabit-by-kabit"><NavDropdown.Item>Kabit by Kabit</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/kabit-savaiye/search"><NavDropdown.Item>Search Kabit Savaiye</NavDropdown.Item></LinkContainer>
              </NavDropdown>
            </li>
            <li>
              <NavDropdown title="Bhai Nand Lal" id="navbarScrollingDropdown">
                <LinkContainer to="/bhai-nand-lal/ghazal"><NavDropdown.Item>Divan-e-Goya : Ghazals</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/bhai-nand-lal/quatrains"><NavDropdown.Item>Rubaayee</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/bhai-nand-lal/zindginama"><NavDropdown.Item>Zindginama</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/bhai-nand-lal/ganjnama"><NavDropdown.Item>Ganjnama</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/bhai-nand-lal/jot-bikas"><NavDropdown.Item>Jot Bikas(Punjabi)</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/bhai-nand-lal/jot-bikas-persian"><NavDropdown.Item>Jot Bikas(Persian)</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/bhai-nand-lal/rahitnama"><NavDropdown.Item>Rahit Nama</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/bhai-nand-lal/tankahnama"><NavDropdown.Item>Tankah Nama</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/bhai-nand-lal/search"><NavDropdown.Item>Search Bhai Nand Lal</NavDropdown.Item></LinkContainer>
              </NavDropdown>
            </li>
            <li>
              <NavDropdown className='res-customs' title="Sundar Gutka" id="navbarScrollingDropdown">
                <LinkContainer to="/sundar-gutka"><NavDropdown.Item>Introduction</NavDropdown.Item></LinkContainer>
                <NavDropdown title="Nitnem" className='second-level' id="navbarScrollingDropdown" >
                  <LinkContainer to="/baanis/japji-sahib"><NavDropdown.Item>Japji Sahib</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/jaap-sahib"><NavDropdown.Item>Jaap Sahib</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/tvai-prasadh-savaiye"><NavDropdown.Item>Tvai Prasadh Savaiye</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/chaupai-sahib"><NavDropdown.Item>Chaupai Sahib</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/anand-sahib"><NavDropdown.Item>Anand Sahib</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/rehraas-sahib"><NavDropdown.Item>Rehraas Sahib</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/kirtan-sohila"><NavDropdown.Item>Kirtan Sohila</NavDropdown.Item></LinkContainer>
                </NavDropdown>
                <NavDropdown title="Guru Granth Sahib" className='second-level' id="navbarScrollingDropdown" >
                  <LinkContainer to="/baanis/anand-sahib-bhog"><NavDropdown.Item>Anand Sahib(Bhog)</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/aarti"><NavDropdown.Item>Arti</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/laavan-anand-karaj"><NavDropdown.Item>Laavan(Anand Karaj)</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/asa-ki-vaar"><NavDropdown.Item>Asa Ki VAar</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/sukhmani-sahib"><NavDropdown.Item>Sukhmani Sahib</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/sidh-gosht"><NavDropdown.Item>Sidh Gosht</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/ramkali-sadh"><NavDropdown.Item>Ramkali Sadh</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/dhakanee-oankaar"><NavDropdown.Item>Dhakanee Oankaar</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/baavan-akhree"><NavDropdown.Item>Baavan Akhree</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/shabad-hazare"><NavDropdown.Item>Shabad Hazare</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/baarah-maaha"><NavDropdown.Item>Baarah Maaha</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/sukhmana-sahib"><NavDropdown.Item>Sukhmana Sahib</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/dukh-bhanjani-sahib"><NavDropdown.Item>Dukh Bhanjani Sahib</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/salok-sehskritee"><NavDropdown.Item>Salok Sehskritee</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/gathaa"><NavDropdown.Item>Gathaa</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/phunhay-m5"><NavDropdown.Item>Phunhay M:5</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/chaubolay-m5"><NavDropdown.Item>Chaubolay M:5</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/salok-kabeer-ji"><NavDropdown.Item>Salok Kabeer ji</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/salok-farid-ji"><NavDropdown.Item>Salok Farid ji</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/savaiye-m1"><NavDropdown.Item>Savaiye M:1</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/savaiye-m2"><NavDropdown.Item>Savaiye M:2</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/savaiye-m3"><NavDropdown.Item>Savaiye M:3</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/savaiye-m4"><NavDropdown.Item>Savaiye M:4</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/savaiye-m5"><NavDropdown.Item>Savaiye M:5</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/salok-m9"><NavDropdown.Item>Salok M:9</NavDropdown.Item></LinkContainer>

                </NavDropdown>
                <NavDropdown title="Dasam granth" className='second-level' id="navbarScrollingDropdown" >
                  <LinkContainer to="/baanis/akal-ustati"><NavDropdown.Item>Akal Ustati</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/bachitar-natak"><NavDropdown.Item>Bachitar Natak</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/shabad-hazare-paatishahi-10"><NavDropdown.Item>Shabad Hazare Paatishahi 10</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/baanis/tvai-prasaadh-savaiye-dheenan-kee"><NavDropdown.Item>Tvai Prasaadh Savai'ye(Dheenan Kee)</NavDropdown.Item></LinkContainer>
                </NavDropdown>
              </NavDropdown>
            </li>
            <li>
              <NavDropdown className='res-customs' title="Resources" id="navbarScrollingDropdown">
                <NavDropdown title="Hukumnama" className='second-level' id="navbarScrollingDropdown" >
                  <LinkContainer to="/hukumnama"><NavDropdown.Item>Hukumnama Index</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/hukum"><NavDropdown.Item>Sri Darbar Sahib</NavDropdown.Item></LinkContainer>
                  <LinkContainer to="/hukumnama/cyber"><NavDropdown.Item>Cyber Hukumnama </NavDropdown.Item></LinkContainer>
                </NavDropdown>
                <LinkContainer to="/sgdv/isg"><NavDropdown.Item>iSearchGurbani</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/sgdv"><NavDropdown.Item>Search Gurbani Desktop Version</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/mahan-kosh"><NavDropdown.Item>GurShabad Ratanakar Mahankosh</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/guru-granth-kosh"><NavDropdown.Item>Sri Guru Granth Kosh</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/sggs-kosh"><NavDropdown.Item>SGGS Kosh</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/sri-nanak-prakash"><NavDropdown.Item>Sri Nanak Prakash</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/sri-gur-pratap-suraj-granth"><NavDropdown.Item>Sri Gur Pratap Suraj Granth</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/faridkot-wala-teeka"><NavDropdown.Item>Faridkot Wala Teeka</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/sri-guru-granth-darpan"><NavDropdown.Item>Sri Guru Granth Darpan</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/maansarovar"><NavDropdown.Item>Maansarovar</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/gurus"><NavDropdown.Item>Guru Sahiban</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/gurus"><NavDropdown.Item>Bhagat Sahiban</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/bhatts"><NavDropdown.Item>Bhatt Sahiban</NavDropdown.Item></LinkContainer>
                <LinkContainer to="/raags"><NavDropdown.Item>Gurbani Raags</NavDropdown.Item></LinkContainer>
              </NavDropdown>
            </li>
          </ul>
          <div className='only-mobile'>
            <h5>Home</h5>
            <Button variant="menu-2" onClick={handleShow}><i class="bi bi-list"></i></Button>


          </div>
        </Container>
      </section>
      <Modal show={share} onHide={handleShareClose}>
        <Modal.Header closeButton>
          <Modal.Title>Share</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='socia-share'>
            <ul>
              <li>
                <Link className='soc-icon' onClick={() => handleShareModal('facebook')}>
                  <img src={facebook} class="img-fluid donate" alt="Responsive image" />
                </Link>
              </li>
              <li>
                <Link className='soc-icon' onClick={() => handleShareModal('twitter')}>
                  <img src={twitter} class="img-fluid donate" alt="Responsive image" />
                </Link>
              </li>
              <li>
                <Link className='soc-icon' onClick={() => handleShareModal('telegram')}>
                  <img src={telegram} class="img-fluid donate" alt="Responsive image" />
                </Link>
              </li>
              <li>
                <Link className='soc-icon' onClick={() => handleShareModal('whatsapp')}>
                  <img src={whatsapp} class="img-fluid donate" alt="Responsive image" />
                </Link>
              </li>
              <li>
                <Link className='soc-icon' onClick={() => handleShareModal('mail')}>
                  <img src={mail} class="img-fluid donate" alt="Responsive image" />
                </Link>
              </li>
            </ul>
          </div>

        </Modal.Body>
      </Modal>
      <Modal className='reg-now' show={isRegister} onHide={handleRegisterClose}>
        <Modal.Header className='p-4' closeButton>
        </Modal.Header>
        <Modal.Body className='p-4'>
          <div className='login-logo-wr'>
            <h1 className='text-dark' >Register</h1>
          </div>
          <div className='login-logo-wr'>
            <img src={logot} class="img-fluid login-logo" alt="Responsive image" />
          </div>
          <div className='register-form'>
            <FloatingLabel
              controlId="floatingInput"
              label="First Name"
              className="mb-2">
              <Form.Control type="email" placeholder="name@example.com"
                required
                value={firstname}
                onChange={e => setFirstName(e.target.value)} />
            </FloatingLabel>
            {firstname === "" && error ? (
              <Validation message={'Please enter first name'} />
            ) : null}
            <FloatingLabel
              controlId="floatingInput"
              label="Last Name"
              className="mb-2">
              <Form.Control type="email" placeholder="name@example.com"
                value={lastname}
                onChange={e => setLastName(e.target.value)} />
            </FloatingLabel>
            {lastname === "" && error ? (
              <Validation message={'Please enter last name'} />
            ) : null}
            <FloatingLabel
              controlId="floatingInput"
              label="Email address"
              className="mb-2"
            >
              <Form.Control type="email" placeholder="name@example.com"
                value={email}
                onChange={e => setEmail(e.target.value)}
                onBlur={handleEmail} />
            </FloatingLabel>
            {email === '' && error ? (
              <Validation message={'Please enter  email'} />
            ) : null}
            {email !== "" && emailError ? (
              <Validation message={'Please enter valid email'} />
            ) : null}
            <FloatingLabel controlId="floatingPassword" className='mb-2' label="Password">
              <Form.Control type="password" placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)} />
              <button className='p-view' >
                {/* <i class="bi bi-eye"></i> */}
                {/* <i class="bi bi-eye-slash"></i> */}
              </button>
            </FloatingLabel>
            {password === "" && error ? (
              <Validation message={'Please enter password'} />
            ) : null}
            <FloatingLabel controlId="floatingPassword" className='' label="Confirm Password">
              <Form.Control type="password" placeholder="Password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)} />
              <button className='p-view' >
                {/* <i class="bi bi-eye"></i> */}
                {/* <i class="bi bi-eye-slash"></i> */}
              </button>
            </FloatingLabel>
            {confirmPassword === "" && error ? (
              <Validation message={'Please enter confirm password'} />
            ) : null}
            {confirmPassword !== "" && password !== '' && pError ? (
              <Validation message={'Password and Confirm Password must be same'} />
            ) : null}

            <button className='btn-primary login w-100 mt-4' onClick={(e) => { e.preventDefault(); handleRegister() }} >Register Now</button>

            {/* <h6 className='text-dark text-center mt-4 ' >Register with</h6>
            <div className='d-flex justify-content-center mt-4'>
              <Link className='soc-icon' onClick={() => handleShareModal('facebook')}>
                <img src={facebook} class="img-fluid donate" alt="Responsive image" />
              </Link>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={isLogin} onHide={handleLoginClose}>
        <Modal.Header closeButton>
        </Modal.Header>

        <Modal.Body className='p-5'>
          <div className='login-logo-wr'>
            <h1 className='text-dark' >Login</h1>
          </div>
          <div className='login-logo-wr'>
            <img src={logot} class="img-fluid login-logo" alt="Responsive image" />
          </div>
          <div className='register-form'>
            <FloatingLabel
              controlId="floatingInput"
              label="Email address"
              className="mb-2"
            >
              <Form.Control type="email" placeholder="name@example.com"
                value={email}
                onChange={e => setEmail(e.target.value)} />
            </FloatingLabel>
            {email === "" && error ? (
              <Validation message={'Please enter password'} />
            ) : null}
            <FloatingLabel controlId="floatingPassword" label="Password">
              <Form.Control type="password" placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)} />
              <button className='p-view' >
                {/* <i class="bi bi-eye"></i> */}
                {/* <i class="bi bi-eye-slash"></i> */}
              </button>
            </FloatingLabel>
            {password === "" && error ? (
              <Validation message={'Please enter password'} />
            ) : null}
            {/*  <label className='my-3 d-flex'> < a href='#'>Forgot password?</a></label> */}
            <button className='btn-primary login w-100 mt-4' onClick={(e) => { e.preventDefault(); handleLogin() }}>Login Now</button>

            {/*  <h6 className='text-dark text-center mt-4 ' >Login with</h6>
            <div className='d-flex justify-content-center mt-4'>
              <Link className='soc-icon' onClick={() => handleShareModal('facebook')}>
                <img src={facebook} class="img-fluid donate" alt="Responsive image" />
              </Link>
            </div> */}
          </div>
        </Modal.Body>
        {/* 
   <Modal.footer> */}

        {/* 
   </Modal.footer>
   */}
      </Modal>

    </header>




  )
}

export default Header