import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function SavaiyeM2() {
    return (
        <div>
            <SGCommonPage title="Savaiye M:2" apiName="savaiye-m2" audioName="savaiye_m2" tlPage="3" /> 
        </div>
    )
}

export default SavaiyeM2