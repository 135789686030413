import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function SavaiyeM3() {
    return (
        <div>
            <SGCommonPage title="Savaiye M:3" apiName="savaiye-m3" audioName="savaiye_m3" tlPage="3" />  
        </div>
    )
}

export default SavaiyeM3