import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function JaapSahib() {
    return (
        <div>
            <SGCommonPage title="Jaap Sahib" apiName="jaap-sahib" audioName="jaap_sahib" tlPage="33" /> 
        </div>
    )
}

export default JaapSahib