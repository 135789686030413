import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function GGSShabadHazare() {
    return (
        <div>
            <SGCommonPage title="Shabad Hazare" apiName="shabad-hazare" audioName="shabad_hazare" tlPage="5" /> 
        </div>
    )
}

export default GGSShabadHazare