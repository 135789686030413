import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useOutletContext } from "react-router";
import { Link, useLocation } from "react-router-dom";
import facebook from '../assets/img/facebook.svg';
import twitter from '../assets/img/twitter.svg';
import youtube from '../assets/img/youtube.svg';
import telegram from '../assets/img/telegram.svg';
import whatsapp from '../assets/img/whatsapp.svg';
import mail from '../assets/img/mail.svg';
import '../assets/css/ang-by-ang.css'

const ShareLink = (props) => {
    const location = useLocation();
    const data = location.state;
    const navigate = useNavigate();
    const handleShareModal = (platform) => {
        const shareUrl = 'https://isearchgurbani.sicsglobal.com';
        const locationPathname = location.pathname; // You might need to import `useLocation` from `react-router-dom`
        const title = props.data.punjabi || "Default Title"; // You can use a specific title from the item or a default one

        let shareLink = '';

        switch (platform) {
            case 'facebook':
                shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl + locationPathname)}&t=${encodeURIComponent(title)}`;
                break;
            case 'twitter':
                shareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl + locationPathname)}&text=${encodeURIComponent(title)}`;
                break;
            case 'youtube':
                shareLink = `https://www.youtube.com`;
                break;
            case 'telegram':
                shareLink = `https://telegram.me/share/url?url=${encodeURIComponent(shareUrl + locationPathname)}&text=${encodeURIComponent(title)}`;
                break;
            case 'whatsapp':
                shareLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(title + " " + shareUrl + locationPathname)}`;
                break;
            case 'mail':
                shareLink = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(shareUrl + locationPathname)}`;
                break;
            default:
                break;
        }

        window.open(shareLink, '_blank');
    };
    return (
        <div className='socia-share' style={{ marginLeft: '600px' }}>
            <ul>
                <li><button className='ang-btn' onClick={(e) => { e.preventDefault(); navigate('/guru-granth-sahib/shabad/' + `${props.data.shabad_id}` + '/line/' + `${props.data.shabdlineID}`) }}>Shabad View</button></li>
                <li><button className='ang-btn' onClick={(e) => { e.preventDefault(); navigate('/guru-granth-sahib/verse/' + `${props.data.verseID}`) }}>Verse View</button></li>
                <li>
                    <Link className='soc-icon' onClick={() => handleShareModal('facebook' )} >
                        <img src={facebook} class="img-fluid donate" alt="Responsive image" />
                    </Link>
                </li>
                <li>
                    <Link className='soc-icon' onClick={() => handleShareModal('twitter' )} >
                        <img src={twitter} class="img-fluid donate" alt="Responsive image" />
                    </Link>
                </li>
                <li>
                    <Link className='soc-icon' onClick={() => handleShareModal('telegram' )} >
                        <img src={telegram} class="img-fluid donate" alt="Responsive image" />
                    </Link>
                </li>
                <li>
                    <Link className='soc-icon' onClick={() => handleShareModal('whatsapp' )} >
                        <img src={whatsapp} class="img-fluid donate" alt="Responsive image" />
                    </Link>
                </li>
                <li>
                    <Link className='soc-icon' onClick={() => handleShareModal('mail' )} >
                        <img src={mail} class="img-fluid donate" alt="Responsive image" />
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default ShareLink;
