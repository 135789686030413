const charMap = {
    'a': 'ਅ',
    'A': 'ੳ',
    'b': 'ਬ',
    'B': 'ਭ',
    'c': 'ਚ',
    'C': 'ਛ',
    'd': 'ਦ', 
    'D': 'ਧ',
    'e': 'ੲ',
    'E': 'ਓ',
    'f': 'ਡ', 
    'F': 'ਢ',
    'g': 'ਗ',
    'G': 'ਘ', 
    'h': 'ਹ',   
    'H': '੍ਹ',
    'i': 'ਿ',
    'I': 'ੀ',
    'j': 'ਜ',
    'J': 'ਝ',
    'k': 'ਕ',
    'K': 'ਖ',
    'l': 'ਲ',
    'L': 'ਲ੍',
    'm': 'ਮ',
    'M': 'ੰ',
    'n': 'ਨ',
    'N': 'ਂ',
    'o': 'ੋ',
    'O': 'ੌ',
    'p': 'ਪ',
    'P': 'ਫ',
    'q': 'ਤ',
    'Q': 'ਥ',
    'r': 'ਰ',
    'R': '੍ਰ',
    's': 'ਸ',
    'S': 'ਸ਼',
    't': 'ਟ',
    'T': 'ਠ',
    'u': 'ੁ',
    'U': 'ੂ',
    'v': 'ਵ',
    'V': 'ੜ',
    'w': 'ਾ',
    'W': 'ਾਂ',
    'x': 'ਣ',
    'X': 'ਯ',
    'y': 'ੇ',
    'Y': 'ੈ',
    'z': 'ਗ਼',
    'Z': 'ਜ਼',
    '0': '੦',
    '1': '੧',
    '2': '੨',
    '3': '੩',
    '4': '੪',
    '5': '੫',
    '6': '੬',
    '7': '੭',
    '8': '੮',
    '9': '੯',
    '\\':'ਞ', 
    '/': '॥',
    ';': 'ਃ',
    // Add more mappings if necessary
  };
  
  export default charMap;
  