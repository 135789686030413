import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function Gathaa() {
    return (
        <div>
            <SGCommonPage title="Gathaa" apiName="gathaa" audioName="gathaa" tlPage="3" /> 
        </div>
    )
}

export default Gathaa