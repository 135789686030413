import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from "react-router";
import { Link, useLocation } from "react-router-dom";
import '../assets/css/dashboard.css';
import '../assets/css/advan-search.css';
import Axios from 'axios';
import { API } from "../config/api";
import { ApiHelper } from '../helpers/ApiHelper';
import Form from 'react-bootstrap/Form';
import searchbannar from '../assets/img/search-bannar.webp';
import Table from 'react-bootstrap/Table';
import charMap from '../components/GurumukhiAscii';
import VirtualKeyboard from '../components/VirtualKeyboard';
import Switch from 'react-switch';
import DataTable from 'react-data-table-component';

function AdvancedSearch(props) {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [isAsvanceSearch, setIsAdvanceSearch] = useState(false);
    const [isAutocomplete, setIsAutocomplete] = useState(false);
    const [isKeyboard, setIsKeyBoard] = useState(true);
    const [selectedOption, setSelectedOption] = useState('FL_begin');
    const [selectedLanguage, setSelectedLanguage] = useState('ROMAN');
    const [searchData, setSearchData] = useState([]);
    const [englishInput, setEnglishInput] = useState(''); // English input
    const [displayedInput, setDisplayedInput] = useState('');
    const [authorArr, setAuthorArr] = useState([]);
    const [ragaArr, setRagaArr] = useState([]);
    const [categoryArr, setCategoryArr] = useState([]);
    const [isfilter, setIsFilter] = useState(true);
    const [selectedAuthor, setSelectedAuthor] = useState('');
    const [scripture, setScripture] = useState('ggs');
    const [pageFrom, setPageFrom] = useState('');
    const [pageTo, setPageTo] = useState('');
    const [searchAllResult, setSearchAllResult] = useState([]);
    const [selectedRaag, setSelectedRaag] = useState('');
    const [selectedCat, setSelectedCat] = useState('');
    const [activeTab, setActiveTab] = useState('Granth');
    useEffect(() => {
        console.log("@@@@@@@@@", props.scripture)
        setPageFrom(props.pageFrom);
        setPageTo(props.pageTo);
        setScripture(props.scripture);
        getAuthors();
        getRaga();
        getCategory();
    }, [])
    const getAuthors = async () => {
        setLoader(true)
        await ApiHelper.get(API.getAuthor)
            .then((resData) => {
                setLoader(false);
                console.log('Author', resData);
                setAuthorArr(resData.data);
            })
            .catch((err) => {
                setLoader(false);
                console.log(err, 'err');
            });
    }
    const getRaga = async () => {
        setLoader(true)
        await ApiHelper.get(API.getRagas)
            .then((resData) => {
                setLoader(false);
                console.log('Raga', resData);
                setRagaArr(resData.data);
            })
            .catch((err) => {
                setLoader(false);
                console.log(err, 'err');
            });
    }
    const getCategory = async () => {
        setLoader(true)
        await ApiHelper.get(API.getAllCategory)
            .then((resData) => {
                setLoader(false);
                console.log('category', resData.data);
                setCategoryArr(resData.data);
            })
            .catch((err) => {
                setLoader(false);
                console.log(err, 'err');
            });
    }
    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
        console.log('Selected language:', event.target.value);
        setEnglishInput('');
        setDisplayedInput('');
    };
    const transliterateToGurumukhi = (input) => {
        return input
            .split('')
            .map((char) => charMap[char.toLowerCase()] || charMap[char.toUpperCase()] || char)
            .join('');
    };
    const handleInputChange = (e) => {
        const input = e.target.value;
        if (selectedLanguage === 'PUNJABI-ASC') {
            // Check if the input length has decreased (backspace)
            if (input.length < displayedInput.length) {
                const newEnglishInput = englishInput.slice(0, -1);
                setEnglishInput(newEnglishInput);
                setDisplayedInput(transliterateToGurumukhi(newEnglishInput));
            } else {
                // Append the new character to the previous input
                const newChar = input[input.length - 1];
                const newEnglishInput = englishInput + newChar;
                setEnglishInput(newEnglishInput);
                setDisplayedInput(transliterateToGurumukhi(newEnglishInput));
            }
        } else {
            setDisplayedInput(input);
        }
    };
    const handleItemClick = (item) => {
        setDisplayedInput(item.word);  // Set the selected word to input field
        setSearchData([]);  // Clear the dropdown
        setIsAutocomplete(false);
        getSearchResult(item.word);
    };
    const addChar = (char) => {
        setDisplayedInput((prevValue) => prevValue + char);
    };
    useEffect(() => {
        if (displayedInput !== "" && isAutocomplete === true) {
            getSearchWords();
            getSearchResult(selectedLanguage === 'PUNJABI-ASC' ? englishInput : displayedInput)
        }
        else if (displayedInput !== "" && isAutocomplete === false) {
            getSearchResult(selectedLanguage === 'PUNJABI-ASC' ? englishInput : displayedInput)
        }
        else {
            setSearchData([])
        }
        /* if (displayedInput === "") {
            setSearchData([])
        } */
    }, [displayedInput])
    useEffect(() => {
        if (englishInput === "") {
            setSearchData([])
        }
    }, [englishInput])

    const getSearchWords = async () => {
        setLoader(true)
        const params = new FormData();
        params.append('q', selectedLanguage === 'PUNJABI-ASC' ? englishInput : displayedInput);
        params.append('searchtype', selectedOption);
        params.append('language', selectedLanguage);
        params.append('ggs', 'true');
        params.append('ak', 'true');
        params.append('bgv', 'true');
        params.append('dg', 'true');
        params.append('ks', 'true');
        params.append('bnl', 'true')

        console.log('para', params)
        /* await ApiHelper.post(API.uploadFile, params) */
        await Axios.post(API.getAllSearchWords, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((resData) => {
                setLoader(false)
                console.log('getSearch', resData.data.allwords);
                setSearchData(resData.data.allwords)
            })
            .catch((err) => {
                setLoader(false)
                console.log(err);
            })
    }
    const getSearchResult = async (key) => {
        setLoader(true)
        const params = new FormData();
        params.append('start', '0');
        params.append('length', '20');
        params.append('Searchtype', selectedOption);
        params.append('language', selectedLanguage);
        params.append('scripture', scripture);
        params.append('SearchData', key ? key : displayedInput);
        params.append('author', selectedAuthor);
        params.append('raag', selectedRaag);
        params.append('page_from', pageFrom);
        params.append('page_to', pageTo)
        params.append('bnlSelect', selectedCat)

        console.log('para', params)
        /* await ApiHelper.post(API.uploadFile, params) */
        await Axios.post(API.getSearchData, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((resData) => {
                setLoader(false)
                console.log('getSearchRESULT', resData.data.data);
                setSearchAllResult(resData.data.data)
            })
            .catch((err) => {
                setLoader(false)
                console.log(err);
            })
    }
    const columns = [
        {
            name: 'ID',
            selector: 'serialNumber',
            sortable: false,
            cell: (row, index) => <div>{index + 1}</div>,
            width: '69px'
        },
        {
            name: "Phonetic Roman",
            selector: row => row.translit,
        },
        {
            name: "Gurumukhi Unicode",
            selector: row => row.punjabi,
        },
        {
            name: "",
            cell: (row) => (
                props.scripture === "ggs" || props.scripture === "ak" ?
                    <div className='btn-dis'>
                        <button className='ang-btn'
                            onClick={() => navigate('/' + `${row.pageLink}`)}>Go to page</button>
                    </div> : null
            )
        },
    ]
    const customStyleTable = {
        head: {
            style: {
                fontSize: '15px',
                fontWeight: 500,
                backgroundColor: "#F5F5F5"
            },
        },
        headRow: {
            style: {
                backgroundColor: '#66666',
                minHeight: '52px',
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
            },
        },
        rows: {
            style: {
                position: 'relative',
                cursor: 'pointer', // Change cursor to pointer for the whole row
            },
        },
    };
    const handleClick = (row) => {
        console.log('ROWWWW', row.shabadlink)
        /* setRowItem(row); */
        navigate('/' + `${row.shabadlink}`)
    }
    useEffect(() => {
        //setActiveTab(scriptureToTabEventKey[scripture]);
    }, [scripture]);
    useEffect(() => {
        if (scripture) {
            getSearchResult(selectedLanguage === 'PUNJABI-ASC' ? englishInput : displayedInput);
        }
    }, [scripture, pageFrom, pageTo]);
    return (
        <div>
            <div>
                <section className='section-1'>

                    <div className="fouth-container-adv common-padding">
                        <div className="container align-items-center d-flex justify-content-center">
                            <div class="row w-60 search-main-grd">
                                <div class="col-12">
                                    <h1 className="main-heading">{props.title}</h1>
                                </div>

                                <div className="position-relative">
                                    <input className="form-control border-secondary py-2 search-r" type="search" placeholder='Search'
                                        value={displayedInput} // Display Gurumukhi ASCII in the input field
                                        onChange={handleInputChange} />
                                    {isAutocomplete ? searchData.length > 0 && (
                                        <ul className='select-dropdown'>
                                            {searchData.map((item, index) => (
                                                <li key={index} style={{ padding: '10px', borderBottom: '1px solid #ddd' }}
                                                    onClick={() => handleItemClick(item)}
                                               /*  onClick={(e) => {e.preventDefault(); setSearchData([]);  setDisplayedInput(item.word);getSearchResult(item.word); }} */>
                                                    {item.word}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : null}
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary search-divz" type="button"
                                            onClick={(e) => { e.preventDefault(); getSearchResult(selectedLanguage === 'PUNJABI-ASC' ? englishInput : displayedInput) }}>
                                            <i className="bi bi-search"></i>
                                        </button>
                                    </div>
                                </div>

                                <div class="filter">
                                    <div class="main-filters">
                                        <div className='row g-3'>
                                            <div class="col-lg-4">
                                                <div className="form-control auto-com-check">
                                                    <label for="vehicle1" className='check-label'>Autocomplete</label>
                                                    <input type="checkbox" className='checkbox' value="Bike"
                                                        onChange={(e) => { setIsAutocomplete(e.target.checked) }} checked={isAutocomplete ? 'checked' : ''} />
                                                </div>
                                            </div>

                                            <div class="col-lg-4">
                                                <Form.Select aria-label="Default select example" value={selectedOption}
                                                    onChange={(e) => setSelectedOption(e.target.value)} >
                                                    <option selected >Return Results</option>
                                                    <option value="FL_begin">First Letter Beginning</option>
                                                    <option value="FL_any">First Letter Anywhere</option>
                                                    <option value="PHRASE">Phrase</option>
                                                </Form.Select>
                                            </div>

                                            <div class="col-lg-4">
                                                <Form.Select aria-label="Default select example" value={selectedLanguage} onChange={handleLanguageChange}>
                                                    <option selected>Find results in language</option>
                                                    <option value="ROMAN">Phonetic Roman</option>
                                                    <option value="PUNJABI-ASC">Gurumukhi ASCII</option>
                                                    <option value="PUNJABI">Gurumukhi Unicode</option>
                                                </Form.Select>
                                            </div>
                                            {props.scripture === 'bnl' ?
                                            <>
                                            <div class="col-lg-6">
                                                    <Form.Select aria-label="Find results from Category"
                                                        onChange={(e) => setSelectedCat(e.target.value)}>
                                                        <option value="">All Category</option>
                                                        {categoryArr.map((cat, index) => (
                                                            <option value={cat.name}>{cat.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>
                                                
                                                <div class="col-lg-6">
                                                <div className="form-control auto-com-check">
                                                    <label for="vehicle1" className='check-label'>Search from</label>
                                                    <div data-mdb-input-init class="form-outline d-flex count-div" >
                                                        <input type="number" id="typeNumber" class="form-control no-up"
                                                            onChange={(e) => setPageFrom(e.target.value)} value={pageFrom} />
                                                        <p className='and-para'>to</p>
                                                        <input type="number" id="typeNumber" class="form-control no-up"
                                                            onChange={(e) => setPageTo(e.target.value)} value={pageTo} />
                                                    </div>
                                                </div>
                                            </div>
                                                
                                            </>
                                                 : null}

                                            {props.scripture === "ggs" || props.scripture === "ak" ?
                                                <>
                                                    <div class="col-lg-6">
                                                        <Form.Select aria-label="Find results from text related to raag"
                                                            onChange={(e) => setSelectedRaag(e.target.value)}>
                                                            <option value="">Any Raag</option>
                                                            {ragaArr.map((rag, index) => (
                                                                <option value={rag.id}>{rag.name}</option>
                                                            ))}
                                                        </Form.Select>


                                                    </div>

                                                    <div class="col-lg-6">
                                                        <Form.Select aria-label="Find results from text written by"
                                                            onChange={(e) => setSelectedAuthor(e.target.value)}>
                                                            <option value=""> Any Author</option>
                                                            {authorArr.map((author, index) => (
                                                                <option value={author.ID}>{author.author}</option>
                                                            ))}
                                                        </Form.Select>

                                                    </div>
                                                </>
                                                : null}
                                            

                                            {props.scripture !== "bnl" && 
                                            <div class="col-lg-12">
                                            <div className="form-control auto-com-check">
                                                <label for="vehicle1" className='check-label'>Find results from page between</label>
                                                <div data-mdb-input-init class="form-outline d-flex count-div" >
                                                    <input type="number" id="typeNumber" class="form-control no-up"
                                                        onChange={(e) => setPageFrom(e.target.value)} value={pageFrom} />
                                                    <p className='and-para'>and</p>
                                                    <input type="number" id="typeNumber" class="form-control no-up"
                                                        onChange={(e) => setPageTo(e.target.value)} value={pageTo} />
                                                </div>
                                            </div>
                                        </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                {selectedLanguage === "PUNJABI" ?
                    <section className='p-5'>
                        <div className='container'>
                            <div className=' p-4'>
                                {/*  <h1 className='inner-heading' >Sri Guru Granth Sahib</h1> */}
                                <button className='ang-btn mx-2' onClick={(e) => { e.preventDefault(); setIsKeyBoard(!isKeyboard) }}>Show Keyboard</button>
                                <label className='me-2' >You can use either Virtual or Physical Keyboard for Gurmukhi Unicode </label>
                            </div>
                            {isKeyboard ?
                                <VirtualKeyboard addChar={addChar} />
                                : null}
                        </div>
                    </section>
                    : null}
                {displayedInput !== "" ?
                    <section className='search-result common-padding' >

                        <div className='container-lg'>
                            <h1 className='main-heading' >Search Results</h1>
                            <div className='search-tab-main-wrapper' >
                                <div className='table-responsive mt-4'>
                                    <DataTable
                                        columns={columns}
                                        data={searchAllResult}
                                        pagination
                                        fixedHeader
                                        customStyles={customStyleTable}
                                        onRowClicked={handleClick}
                                    /* fixedHeaderScrollHeight={tableHeight} */
                                    />
                                </div>
                            </div>
                        </div>
                    </section> : null}
            </div>
        </div>
    )
}

export default AdvancedSearch