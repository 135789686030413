import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function Baavan() {
    return (
        <div>
            <SGCommonPage title="Baavan Akhree" apiName="baavan-akhree" audioName="baavan_akhree" tlPage="28" /> 
        </div>
    )
}

export default Baavan