import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function JapjiSahib() {
    return (
        <div>
            <SGCommonPage title="Japji Sahib" apiName="japji-sahib" audioName="japji_sahib" tlPage="16"/> 
        </div>
    )
}

export default JapjiSahib