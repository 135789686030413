import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function Chaubolay() {
    return (
        <div>
            <SGCommonPage title="Chaubolay M:5" apiName="chaubolay-m5" audioName="chaubolay_m5" tlPage="1" /> 
        </div>
    )
}

export default Chaubolay