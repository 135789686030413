import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function AkalUstati() {
    return (
        <div>
            <SGCommonPage title="Akal Ustati" apiName="akal-ustati" audioName="akal_ustati" tlPage="22" /> 
        </div>
    )
}

export default AkalUstati