import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function Phunhay() {
    return (
        <div>
            <SGCommonPage title="Phunhay M:5" apiName="phunhay-m5" audioName="phunhay_m5" tlPage="4" /> 
        </div>
    )
}

export default Phunhay