import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function AkalUstati() {
    return (
        <div>
            <SGCommonPage title="Bachitar Natak" apiName="bachitar-natak" audioName="bachitar_natak" tlPage="40" /> 
        </div>
    )
}

export default AkalUstati