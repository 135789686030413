import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from "react-router";
import { Link, useLocation } from "react-router-dom";
import '../../../assets/css/dashboard.css';
import '../../../assets/css/intro.css';
import '../../../assets/css/nanak.css';
import Axios from 'axios';
import nanak from '../../../assets/img/nanak.jpg';
import { API } from "../../../config/api";
import { ApiHelper } from '../../../helpers/ApiHelper';
//import imgs from './assets/img/content/ggs_01.jpg'
import introbannar from '../../../assets/img/intro-bannar.webp';
import Spinner from '../../../components/Spinner';

function GuruNanak() {
    const [datas, setDatas] = useState([]);
    const [error, setError] = useState(false)
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        getData()
    }, [])
    const getData = async () => {
        await Axios.get('https://gurbani.sicsglobal.com/api/meta?url=gurus/gurunanak')
            .then((resData) => {
                console.log('INTRO', resData.data.data);
                setDatas(resData.data.data)

            })
            .catch((err) => {
                console.log(err);
            })
    }
    
    return (
        <div>
            <section className='section-1'>
                <div className=" justify-content-md-center align-items-center">
                    <div className='banner-img'>
                        <img src={introbannar} class="img-fluid" alt="Responsive image" />
                    </div>
                </div>
                <div className='Gurbani-Raags p-4'>
                    <div className='container'>
                        <h1 class="inner-heading mb-4"></h1> 
                        <div className="row ">
                            <div class="col-lg-12">
                                <div class="px-1 py-1 align-middle mt-0 akl-intro">  
                                <p  class="no-top">Sri Guru Nanak Dev Ji </p>
                                <div className='nanak-img mt-2 mb-4'  align="center">
                                    <img src={nanak} class="nanak-imgs" alt="Responsive image" />
                                </div>
                                <div className='list-div'>
                                    <div className='row'>
                                <div class="col-lg-1"></div>
                                <div class="col-lg-5">
                                <strong className="index-text">INDEX</strong>
                                <ul  className='first-list'>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak'>GURU'S SCHOOLING</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak'>CEREMONY OF SACRED THREAD</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak'>COBRA SERVES THE DIVINE MASTER</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak'>GURU SITS IN SECLUSION</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak2'>TRUE BARGAIN</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak2'>GURU'S MARRIAGE</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak2'>GURU NANAK COMES TO SULTANPUR</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak2'>GURU'S DISAPPEARANCE</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak2'>TRAVELS OF GURU NANAK</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak2'>FIRST STOP AT EMINABAD</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak3'>SUJJAN THUG</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak3'>GURU NANAK AT HARDWAR</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak3'>GURU AT GORAKHMATA</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak3'>REETHA SAHIB</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak3'>GURU AT BANARAS</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak4'>GURU AT GAYA</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak4'>GURU TO KAMRUP</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak5'>KAUDA RAKHSHASH</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak5'>GURU AT JAGAN NATH PURI</Link></li>     
                                </ul>
                                    </div> 
                                <div class="col-lg-5">
                                
                                <ul className='first-list'>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak6'>RETURN FROM SANGALDEEP</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak6'>GURU TO SARSA</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak6'>TO SULTANPUR</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak6'>HOME COMING</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak6'>FOUNDATION OF KARTARPUR</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak6'>SECOND UDASI</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak7'>GURU TO KAILASH PARBAT</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak7'>THIRD UDASI</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak7'>GURU NANAK AT MECCA</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak7'>GURU NANAK AT MEDENA</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak7'>GURU AT BAGHDAD</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak7'>RETURN FROM BAGHDAD</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak7'>GURU NANAK AND VALI KANDHARI</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak9'>GURU AT SAIDPUR</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak9'>GURU AT KARTARPUR</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak9'>FORMATION OF SANGAT</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak9'>LIVING BY HONEST MEANS</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak9'>COMMON FREE KITCHEN</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak10'>COMPOSITION AND COLLECTION OF BANI</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak10'>FURTHER TRAVEL FROM KARTARPUR</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak10'>GURU AT ACHAL BATALA</Link></li>
                                    <li className='fonts-colors'><Link className='fonts-colors' to='/gurus/gurunanak10'>ASCENSION OF GURU NANAK</Link></li>   
                                </ul>
                                </div>
                                <div class="col-lg-1"></div>
                                </div>
                                 </div>
                                 <div className='table' align="center">
                                 <table className='nanak-table'>
	
	
  <tbody>
      <tr>
        <td>FATHER</td>
        <td>Kalayan Das Ji</td>
        
      </tr>
      <tr>
        <td>MOTHER</td>
        <td>Tripta ji</td>
        
      </tr>
      <tr>
        <td>DATE OF BIRTH</td>
        <td>10/20/1469</td>
       
      </tr>
      <tr>
        <td>PLACE OF BIRTH</td>
        <td>Talwandi (Pak) Nankana Sahib</td>
       
      </tr>
      <tr>
        <td>WIFE</td>
        <td>Sulakhni ji</td>
        
      </tr>

      <tr>
        <td>CHILDREN</td>
        <td>Sri Chand ji & Lakhmi das ji</td>
        
      </tr>

      <tr>
        <td>AGE, YEAR & TIME AT GUR GADHI</td>
        <td>Parkash, 70 years</td>
        
      </tr>

      <tr>
        <td>REGIMES</td>
        <td>Behal, Sakander, Babar, Hamanyu & Ibrahim Lodhi</td>
        
      </tr>

      <tr>
        <td>CONTRIBUTION OF BANIES</td>
        <td>	
        Jap Ji, Sidh Goshat, Sodar, Sohala, Arti Onkar, Asa Di Var, Malar & Madge Di Var, Patti Baramaha. Total 947 Shabads in 19 Rags</td>
        
      </tr>

      <tr>
        <td>AGE</td>
        <td>70</td>
        
      </tr>

      <tr>
        <td>JYOTI-JOT DAY</td>
        <td>05/07/1539</td>
        
      </tr>

      <tr>
        <td>JYOTI JOT PLACEJYOTI-JOT DAY</td>
        <td>Kartar pur</td>
        
      </tr>
     
  </tbody>

</table>
                               
                                 </div>

                                    {datas ?
                                        < div
                                        dangerouslySetInnerHTML={{
                                            __html: datas.html}} 
                                        /> : null}
                                </div>
                            </div>
                        </div>                 

                    </div>
                </div>

                {loader && <Spinner />}
            </section>
            
        </div>
    )
}

export default GuruNanak