import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function BaarahMaaha() {
    return (
        <div>
            <SGCommonPage title="Baarah Maaha" apiName="baarah-maaha" audioName="baarah_maaha" tlPage="5" /> 
        </div>
    )
}

export default BaarahMaaha