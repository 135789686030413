import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from "react-router";
import { Link, useLocation } from "react-router-dom";
import '../assets/css/dashboard.css';
import Axios from 'axios';


function SiteMap() {
   
    return (
        <section className='p-4' >
            <div className='container'>
                <div className='row'>
                    <h1 className='inner-heading mb-4' >Search Gurbani Sitemap</h1>
                    <div className='col-lg-4'>
                        <div className='site-itm'>
                            <h3>Sri Guru Granth Sahib</h3>
                            <ul>
                                <li><Link to='/guru-granth-sahib/introduction'>Introduction</Link></li>
                                <li><Link to='/guru-granth-sahib/ang-by-ang'>Ang by Ang</Link></li>
                                <li><Link to='/guru-granth-sahib/index/chapter'>Chapter Index</Link></li>
                                <li><Link to='/guru-granth-sahib/index/author'>Author Index</Link></li>
                                <li><Link to='/guru-granth-sahib/search'>Search Sri Guru Granth Sahib ji</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='site-itm'>
                            <h3>Amrit Keertan Gutka</h3>
                            <ul>
                                <li><Link to='/amrit-keertan/introduction'>Introduction</Link></li>
                                <li><Link to='/amrit-keertan/page-by-page'>Browse Page by Page</Link></li>
                                <li><Link to='/amrit-keertan/index/chapter'>Chapter Index</Link></li>
                                <li><Link to='/amrit-keertan/index/english'>English Index</Link></li>
                                <li><Link to='/amrit-keertan/index/punjabi'>Punjabi Index</Link></li>
                                <li><Link to='/amrit-keertan/index/hindi'>Hindi Index</Link></li>
                                <li><Link to='/amrit-keertan/search'>Search Amrit Keertan Gutka</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='site-itm'>
                            <h3>Bhai Gurdas Vaaran</h3>
                            <ul>
                                <li><Link to='/bhai-gurdas-vaaran/introduction'>Introduction</Link></li>
                                <li><Link to='/bhai-gurdas-vaaran/pauri-by-pauri'>Browse Pauri by Pauri</Link></li>
                                <li><Link to='/bhai-gurdas-vaaran/index/vaar'>Vaar Index</Link></li>
                                <li><Link to='/bhai-gurdas-vaaran/search'>Search Bhai Gurdas Vaaran</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='site-itm'>
                            <h3>Dasam Granth Sahib</h3>
                            <ul>
                                <li><Link to='/dasam-granth/introduction'>Introduction</Link></li>
                                <li><Link to='/dasam-granth/index/chapter/en'>Chapter Index</Link></li>
                                <li><Link to='/dasam-granth/page-by-page'>Browse Page by Page</Link></li>
                                <li><Link to='/dasam-granth/search'>Search Dasam Granth Sahib</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='site-itm'>
                            <h3>Kabit Bhai Gurdas</h3>
                            <ul>
                                <li><Link to='/kabit-savaiye/kabit-by-kabit'>Browse Kabit by Kabit</Link></li>
                                <li><Link to='/kabit-savaiye/search'>Search Kabit Bhai Gurdas</Link></li>
                            </ul>
                        </div>
                    </div>
                   
                    
                    <div className='col-lg-4'>
                        <div className='site-itm'>
                            <h3>Bhai Nand Lal Baani</h3>
                            <ul>
                                <li><Link to='/bhai-nand-lal/ghazal'>Divan-e-Goya : Ghazals </Link></li>
                                <li><Link to='/bhai-nand-lal/quatrains'>Rubaayee</Link></li>
                                <li><Link to='/bhai-nand-lal/zindginama'>Zindginama</Link></li>
                                <li><Link to='/bhai-nand-lal/ganjnama'>Ganjnama</Link></li>
                                <li><Link to='/bhai-nand-lal/jot-bikas'>Jot Bikas (Punjabi)</Link></li>
                                <li><Link to='/bhai-nand-lal/jot-bikas-persian'>Jot Bikas (Persian)</Link></li>
                                <li><Link to='/bhai-nand-lal/rahitnama'>Rahit Nama</Link></li>
                                <li><Link to='/bhai-nand-lal/tankahnama'>Tankah Nama</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='site-itm'>
                            <h3>Baani's</h3>
                            <ul>
                                <li><Link to='/'>Nitnem</Link>
                                <ul className='sub-sitemap' >
                                    <li><Link to='/baanis/japji-sahib'>Japji Sahib</Link></li>
                                    <li><Link to='/baanis/jaap-sahib'>Jaap Sahib</Link></li>
                                    <li><Link to='/baanis/tvai-prasadh-savaiye'>Tvai Prasadh Savaiye</Link></li>
                                    <li><Link to='/baanis/chaupai-sahib'>Chaupai Sahib</Link></li>
                                    <li><Link to='/baanis/anand-sahib'>Anand Sahib</Link></li>
                                    <li><Link to='/baanis/rehraas-sahib'>Rehraas Sahib</Link></li>
                                    <li><Link to='/baanis/kirtan-sohila'>Kirtan Sohila</Link></li>
                                </ul>
                                </li>
                                <li><Link to='/'>Guru Granth Sahib</Link>
                                <ul className='sub-sitemap' >
                                    <li><Link to='/baanis/anand-sahib-bhog'>Anand Sahib(Bhog) </Link></li>
                                    <li><Link to='/baanis/laavan-anand-karaj'>Laavan(Anand Karaj) </Link></li>
                                    <li><Link to='/baanis/asa-ki-vaar'>Asa Ki Vaar</Link></li>
                                    <li><Link to='/baanis/sukhmani-sahib'>Sukhmani Sahib</Link></li>
                                    <li><Link to='/baanis/sidh-gosht'>Sidh Gosht</Link></li>
                                    <li><Link to='/baanis/ramkali-sadh'>Ramkali Sadh</Link></li>
                                    <li><Link to='/baanis/dhakanee-oankaar'>Dhakanee Oankaar</Link></li>
                                    <li><Link to='/baanis/baavan-akhree'>Baavan Akhree</Link></li>
                                    <li><Link to='/baanis/shabad-hazare'>Shabad Hazare</Link></li>
                                    <li><Link to='/baanis/baarah-maaha'>Baarah Maaha</Link></li>
                                    <li><Link to='/baanis/sukhmana-sahib'>Sukhmana sahib</Link></li>
                                    <li><Link to='/baanis/dukh-bhanjani-sahib'>Dukh Bhanjani Sahib</Link></li>
                                    <li><Link to='/baanis/salok-sehskritee'>Salok Sehskritee</Link></li>
                                    <li><Link to='/baanis/gathaa'>Gathaa</Link></li>
                                    <li><Link to='/baanis/phunhay-m5'>Phunhay M: 5</Link></li>
                                    <li><Link to='/baanis/chaubolay-m5'>Chaubolay M:5</Link></li>
                                    <li><Link to='/baanis/salok-kabeer-ji'>Salok Kabeer ji</Link></li>
                                    <li><Link to='/baanis/salok-farid-ji'>Salok Farid ji</Link></li>
                                    <li><Link to='/baanis/savaiye-m1'>Savaiye M: 1</Link></li>
                                    <li><Link to='/baanis/savaiye-m2'>Savaiye M: 2</Link></li>
                                    <li><Link to='/baanis/savaiye-m3'>Savaiye M: 3</Link></li>
                                    <li><Link to='/baanis/savaiye-m4'>Savaiye M: 4</Link></li>
                                    <li><Link to='/baanis/savaiye-m5'>Savaiye M: 5</Link></li>
                                    <li><Link to='/baanis/salok-m9'>Salok M: 9</Link></li>
                                </ul>
                                </li>
                                <li><Link to='/'>Dasam Granth</Link>
                                <ul className='sub-sitemap' >
                                    <li><Link to='/baanis/akal-ustati'>Akal Ustati</Link></li>
                                    <li><Link to='/baanis/bachitar-nata'>Bachitar Natak</Link></li>
                                </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='site-itm'>
                            <h3>Resources</h3>
                            <ul>
                                <li><Link to='/mahan-kosh'>GurShabad Ratanakar Mahankosh </Link></li>
                                <li><Link to='/guru-granth-kosh'>Sri Guru Granth Kosh</Link></li>
                                <li><Link to='/sri-nanak-prakash'>Sri Nanak Prakash</Link></li>
                                <li><Link to='/sri-gur-pratap-suraj-granth'>Sri Gur Pratap Suraj Granth</Link></li>
                                <li><Link to='/faridkot-wala-teeka'>Faridkot Wala Teeka</Link></li>
                                <li><Link to='/sri-guru-granth-darpan'>Sri Guru Granth Darpan</Link></li>
                                <li><Link to='/maansarovar'>Maansarovar</Link></li>
                                <li><Link to='/'>Compilation of Sri Guru Granth Sahib</Link></li>
                                <li><Link to='/'>Indian Classical Music and Sikh Kirtan </Link></li>
                                <li><Link to='/'>Guru Sahiban</Link></li>
                                <li><Link to='/'>Bhagat Sahiban</Link></li>
                                <li><Link to='/'>Bhatt Sahiban</Link></li>
                                <li><Link to='/'>Gurbani Raags</Link></li>
                                <li><Link to='/sgdv'>Search Gurbani Desktop Version</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='site-itm'>
                            <h3>Hukumnama</h3>
                            <ul>
                                <li><Link to='/hukumnama'>Hukumnama Index</Link></li>
                                <li><Link to='/hukum'>Sri Darbar Sahib</Link></li>
                                <li><Link to='/hukumnama/cyber'>Cyber Hukumnama</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SiteMap