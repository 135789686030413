import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function AsaKiVaar() {
    return (
        <div>
            <SGCommonPage title="Asa Ki Vaar" apiName="asa-ki-vaar" audioName="asa_ki_vaar" tlPage="26" /> 
        </div>
    )
}

export default AsaKiVaar