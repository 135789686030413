import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function Laavan() {
    return (
        <div>
            <SGCommonPage title="Laavan( Anand Karaj)" apiName="laavan-anand-karaj" audioName="laavan_anand_karaj" tlPage="1" /> 
        </div>
    )
}

export default Laavan