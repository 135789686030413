    //Gurbani search//
    import React, { useEffect, useState, useRef } from 'react';
    import { useNavigate, useOutletContext } from "react-router";
    import { Link, useLocation } from "react-router-dom";
    import '../../../assets/css/dashboard.css';
    import '../../../assets/css/style.css';
    import Axios from 'axios';
    import { API } from "../../../config/api";
    import { ApiHelper } from '../../../helpers/ApiHelper';
    import Form from 'react-bootstrap/Form';
    import Tab from 'react-bootstrap/Tab';
    import Tabs from 'react-bootstrap/Tabs';
    import Table from 'react-bootstrap/Table';
    import VirtualKeyboard from '../../../components/VirtualKeyboard';
    import charMap from '../../../components/GurumukhiAscii';
    import searchbannar from '../../../assets/img/search-bannar.webp';
    
    const transliterateToGurumukhi = (input) => {
        return input
            .split('')
            .map((char) => charMap[char] || char)
            .join('');
    };
    function SGGDSearch() {
        const navigate = useNavigate();
        const [loader, setLoader] = useState(false);
        const [displayedInput, setDisplayedInput] = useState('');
        const [isKeyboard, setIsKeyBoard] = useState(true);
        const [searchData, setSearchData] = useState([]);
        const [englishInput, setEnglishInput] = useState('');
        const [input, setInput] = useState('');
        const [transliterated, setTransliterated] = useState('');
        const inputRef = useRef(null);
    
        const handleKeyDown = (event) => {
            const char = event.key;
            if (charMap[char]) {
                event.preventDefault();
                const caretPosition = inputRef.current.selectionStart;
                const newInput = [
                    input.slice(0, caretPosition),
                    charMap[char],
                    input.slice(caretPosition)
                ].join('');
                setInput(newInput);
                console.log('^^^^^', newInput)
                setTransliterated(transliterateToGurumukhi(newInput));
            }
        };
        const handleChange = (event) => {
            const newValue = event.target.value;
            setInput(newValue);
            setTransliterated(transliterateToGurumukhi(newValue));
        };
        const addChar = (char) => {
            setInput((prevValue) => prevValue + char);
        };
        useEffect(() => {
            if (input !== "") {
                getSearchWords();
            }
            else {
                setSearchData([])
            }
        }, [input])
        const getSearchWords = async () => {
            setLoader(true)
            await Axios.get(API.getResourceWords + '?q=' + input + '&table_name=' + 'GurShabad Ratanakar Mahankosh')
                .then((resData) => {
                    setLoader(false)
                    console.log('getSearch', resData.data);
                    setSearchData(resData.data)
                })
                .catch((err) => {
                    setLoader(false)
                    console.log(err);
                })
        }
        const handleAlphaClick = (alpha) => {
            navigate('/maansarovar/words', { state: { Word: alpha } })
        };
        const handleItemClick = (item) => {
            setInput(item.word);  // Set the selected word to input field
            setSearchData([]);
            //getSearchResult(item.word);
        };
        return (
            <div>
                <section className='section-1'>
                    <div className=" justify-content-md-center align-items-center">
                        <div className='banner-img'>
                            <img src={searchbannar} class="img-fluid" alt="Responsive image" />
                        </div>
                    </div>
                </section>
                <section className='browse_by_letter p-5'>
                    <div className='container'>
                        <div className='row'>
                            <h1 className='text-dark text-center mb-3' >Sri Guru Granth Sahib Darpan</h1>
                            <h5 className='text-dark'><strong>Sri Guru Granth Sahib Darpan</strong></h5>
                            
                            <p className='inner-heading  text-dark' >
                            Siri Guru Granth Darpan by Professor Sahib Singh Gurmukhi text to Punjabi (Gurmukhi) translation of all of Siri Guru Granth Sahib.
                            </p>
                            <div className='col-lg-12 center-align ang-ang mt-2 mb-3 '>
                                <Link to={`/sri-guru-granth-darpan/page`}><div className='sub-head-snp'>Browse Page by Page Sri Guru Granth Sahib Darpan</div></Link>
                                
                            </div>
                            <h4 className='inner-heading text-center text-dark' >Sri Guru Granth Sahib Darpan</h4>
                            <div className='position-relative my-3'>
                                <input class="form-control border-secondary py-2 search-r" type="search" placeholder="Search"
                                    value={input}
                                    ref={inputRef}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                />                            
                                <div class="input-group-append"><button class="btn btn-outline-secondary search-divz" type="button" onClick={() => input !== "" ? navigate('/sri-guru-granth-darpan/search-preview', { state: { Word: input } }) : null}><i class="bi bi-search"></i></button></div>
                            </div>
    
                        </div>
                    </div>
                </section>
                <section className='p-3'>
                    <div className='container'>
                        <div className=' p-4'>
                            <button className='ang-btn mx-2' onClick={(e) => { e.preventDefault(); setIsKeyBoard(!isKeyboard) }}>Show Keyboard</button>
                            <label className='me-2' >You can use either Virtual or Physical Keyboard for Gurmukhi Unicode </label>
                        </div>
                        {isKeyboard ?
                            <VirtualKeyboard addChar={addChar} /> : null}
                    </div>
                </section>
            </div>
        )
    }
    
    export default SGGDSearch