import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function TvaiPrasadh() {
    return (
        <div>
            <SGCommonPage title="Tvai Prasadh Savaiye" apiName="tvai-prasadh-savaiye" audioName="tvai_prasadh_savaiye" tlPage="2" /> 
        </div>
    )
}

export default TvaiPrasadh