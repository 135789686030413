import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function SavaiyeM5() {
    return (
        <div>
            <SGCommonPage title="Savaiye M:5" apiName="savaiye-m5" audioName="savaiye_m5" tlPage="5" />  
        </div>
    )
}

export default SavaiyeM5