import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function Salok() {
    return (
        <div>
            <SGCommonPage title="Salok Sehskritee" apiName="salok-sehskritee" audioName="salok_sehskritee" tlPage="11" /> 
        </div>
    )
}

export default Salok