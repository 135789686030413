import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from "react-router";
import { Link, useLocation } from "react-router-dom";
import '../../assets/css/dashboard.css';
import '../../assets/css/sgg-index.css';
import '../../assets/css/style.css';
import Axios from 'axios';
import { API } from "../../config/api";
import { ApiHelper } from '../../helpers/ApiHelper';
import Spinner from '../../components/Spinner';
import Switch from 'react-switch';

function SggsShabadIndex() {
    const [loader, setLoader] = useState(false);
    const [parent, setParent] = useState([]);
    const [child, setChild] = useState([]);
    const [languageCheck, setLanguageCheck] = useState(false);
    
    useEffect(() => {
        getChapter();
    }, [])
    const getChapter = async () => {
        setLoader(true)
        await ApiHelper.get(API.getGgsChapter)
            .then((resData) => {
                setLoader(false);
                console.log('chapter', resData.data);

                const childwithoutparent = resData.data.filter(row => {
                    return row.parentID === 1
                })
                setChild(childwithoutparent)
                console.log('chapter  CHILD', childwithoutparent);
                const newParent = resData.data.filter(row => {
                    return row.pageID === 0 && row.chapterID !== 6
                })
                //setParent(newParent)
                newParent.forEach(p => {
                    const newChilds = resData.data.filter(c => {
                        return c.parentID === p.chapter_id && c.page_id !== 0;
                    });
                    parent.push({ ...p, newChilds });
                }); 

                console.log('PARENT CHILD', parent);                
                
            })
            .catch((err) => {
                setLoader(false);
                /* setMessage("Error !!! Please try again"); */
                console.log(err, 'err');
            });
    }
    const handleChange = (nextChecked) => {
        setLanguageCheck(nextChecked);
    };
    useEffect(() => {
        console.log('hhhkguk', languageCheck)
    }, [languageCheck])


    return (
        <div>
            {loader && <Spinner />}
            <section className='inner-actions p-4' >
                <div className='container'>
                    <div className='row w-100'>
                        <div className='col-lg-12 p-0'>
                            <div className='in-act-wrapper'>
                                <h1 className='inner-heading' ></h1>
                                <div className='actions-mains'>
                                    <div class=" d-flex form-check form-switch">
                                        <label class="form-check-label left-para" for="flexSwitchCheckChecked">Gurmukhi</label>
                                        <label className='switch-sgg'>
                                            {/* <input type='checkbox'></input>
                                                    <span className='slider'></span> */}
                                            <Switch
                                                onChange={handleChange}
                                                checked={languageCheck}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                            />

                                        </label>
                                        <label class="form-check-label right para" for="flexSwitchCheckChecked">English</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div className='d-flex flex-column'>
                        <div className='ang-display mt-5'>
                            <h1>Sri Guru Granth Sahib</h1>
                            <div className='ang-wrapper '>
                                <div className='ang-itm '>
                                    <h2 className='lang-1' >
                                        <details>
                                            <summary>{languageCheck ? "ਪੂਰਵ ਰਾਗ ਭਾਗ" : " Pre Raag Section"}</summary>
                                            {child.map((item, index) => (
                                                <div className='in-act-wrapper mt-2 '>                                                    
                                                    <Link  to={`/guru-granth-sahib/ang/${item.pageID}/line/${item.lineID}`}  className='det-para'><span>{languageCheck ? item.chapterP : item.chapterE}</span></Link>
                                                    <div className='actions-mains'>
                                                        <Link to={`/guru-granth-sahib/ang/${item.pageID}/line/${item.lineID}`}  className='det-no'><span >{item.pageID}</span></Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </details>

                                    </h2>
                                </div>

                                <div className='ang-itm ' /* style={{ background: '#F7FDE9' }} */>
                                    <h2 className='lang-1' >
                                        <details>
                                            <summary>{languageCheck ? "ਰਾਗੁ" : " Raga Section"}</summary>

                                        </details>
                                    </h2>
                                </div>
                                {parent.map((item, index) => (
                                    <div className='ang-itm ' /* style={{ backgroundColor: index % 2 === 0 ? 'white' : '#F7FDE9' }} */>
                                        <h2 className='lang-1' >
                                            <details>
                                                <summary>{languageCheck ? item.chapterP : item.chapterE}</summary>
                                                {item.newChilds?.map((c, cIndex) => (
                                                    <div className='in-act-wrapper mt-2'>
                                                        <Link  to={`/guru-granth-sahib/ang/${c.pageID}/line/${c.lineID}`} className='det-para'><span>{languageCheck ? c.chapterP : c.chapterE}</span></Link>
                                                        <div className='actions-mains'>
                                                            <Link to={`/guru-granth-sahib/ang/${c.pageID}/line/${c.lineID}`} className='det-no'><span >{c.pageID}</span></Link>
                                                        </div>
                                                    </div>
                                                ))}
                                            </details>
                                        </h2>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            {/*<section>
                <div className='container'>
                    <div className='row'>
                        <div className='ang-display sgg mt-4'>
                            <h1>Displaying</h1>
                            <div className='ang-wrapper sgg'>
                               
                               
                                <div className='ang-itm'>
                                    <h2 className='lang-1' >
                                        <details>
                                            <summary>Section 1</summary>
                                            <div className='in-act-wrapper'>
                                                <p className='det-para' ><a href=''>Pre Raag Section</a></p>
                                            <div className='actions-mains'>
                                                <p className='det-no'><a href=''>1</a></p>
                                            </div>
                                            </div>
                                            <div className='in-act-wrapper py-1'>
                                                <p className='det-para' ><a href=''>Pre Raag Section</a></p>
                                            <div className='actions-mains'>
                                                <p className='det-no'><a href=''>1</a></p>
                                            </div>
                                            </div>
                                            <div className='in-act-wrapper'>
                                                <p className='det-para' ><a href=''>Pre Raag Section</a></p>
                                            <div className='actions-mains'>
                                                <p className='det-no'><a href=''>1</a></p>
                                            </div>
                                            </div>
                                        </details>
                                    </h2>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>*/}
        </div>
    )
}

export default SggsShabadIndex