import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function SidhGosht() {
    return (
        <div>
            <SGCommonPage title="Sidh Gosht" apiName="sidh-gosht" audioName="sidh_gosht" tlPage="17" /> 
        </div>
    )
}

export default SidhGosht