import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import '../../../assets/css/dashboard.css';
import '../../../assets/css/intro.css';
import Axios from 'axios';
import '../../../assets/css/bhatts.css';

//import imgs from './assets/img/content/ggs_01.jpg'
import introbannar from '../../../assets/img/intro-bannar.webp';
import Spinner from '../../../components/Spinner';


function BhattSahiban() {
    const navigate = useNavigate();
    const [introData, setIntroData] = useState([]);
    const [error, setError] = useState(false)
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        getIntro()
    }, [])
    const getIntro = async () => {
        await Axios.get('https://gurbani.sicsglobal.com/api/meta?url=bhatts')
            .then((resData) => {
                console.log('INTRO', resData.data.data);
                setIntroData(resData.data.data)

            })
            .catch((err) => {
                console.log(err);
            })
    }
    
    return (
        <div>
            <section className='section-1  back-color'>
                <div className=" justify-content-md-center align-items-center">
                    <div className='banner-img'>
                        <img src={introbannar} class="img-fluid" alt="Responsive image" />
                    </div>
                </div>

                {/*<section>
                    <div className='container py-5'>
                        <div className='row'>
                            <div className='col-lg-12'>
                            <button className='bhatt-bttn mb-5' >Kalshar</button>
                            <button className='bhatt-bttn mb-5'>Jalap</button>
                            <button className='bhatt-bttn mb-5'>Kirat</button>
                            <button className='bhatt-bttn mb-5'>Bhika</button>
                            <button className='bhatt-bttn mb-5'>Salh</button>
                            <button className='bhatt-bttn mb-5'>Bhalh</button>
                            <button className='bhatt-bttn mb-5'>Nal</button>
                            <button className='bhatt-bttn mb-5'>Gyand</button>
                            <button className='bhatt-bttn mb-5'>Mathura</button>
                            <button className='bhatt-bttn mb-5'>Bal</button>
                            <button className='bhatt-bttn mb-5'>Harbans</button>
                            <button className='bhatt-bttn mb-5'>Baba Sunder ji</button>
                            <button className='bhatt-bttn mb-5'>Satta and Balwand</button>
                            <button className='bhatt-bttn mb-5'>Bhai Mardana Ji</button>

                            </div>
                        </div>
                    </div>
                </section>*/}


                <div className="container ">
                    <div className="second-container intro-bkg">
                        <div className="row ">
                            <div class="col-lg-12">
                                    <div class="px-1 py-1 align-middle mt-4 akl-intro">

                            <button className='bhatt-bttn ' onClick={() => navigate('/bhatts/bhatt-kalshar')}>Kalshar</button>
                            <button className='bhatt-bttn ' onClick={() => navigate('/bhatts/bhatt-jalap')}>Jalap</button>
                            <button className='bhatt-bttn ' onClick={() => navigate('/bhatts/bhatt-kirat')}>Kirat</button>
                            <button className='bhatt-bttn ' onClick={() => navigate('/bhatts/bhatt-bhika')}>Bhika</button>
                            <button className='bhatt-bttn ' onClick={() => navigate('/bhatts/bhatt-salh')}>Salh</button>
                            <button className='bhatt-bttn ' onClick={() => navigate('/bhatts/bhatt-bhalh')}>Bhalh</button>
                            <button className='bhatt-bttn ' onClick={() => navigate('/bhatts/bhatt-nalh')}>Nal</button>
                            <button className='bhatt-bttn ' onClick={() => navigate('/bhatts/bhatt-gyand')}>Gyand</button>
                            <button className='bhatt-bttn ' onClick={() => navigate('/bhatts/bhatt-mathura')}>Mathura</button>
                            <button className='bhatt-bttn ' onClick={() => navigate('/bhatts/bhatt-balh')}>Bal</button>
                            <button className='bhatt-bttn ' onClick={() => navigate('/bhatts/bhatt-haribans')}>Harbans</button>
                            <button className='bhatt-bttn ' onClick={() => navigate('/bhatts/sunderbaba')}>Baba Sunder ji</button>
                            <button className='bhatt-bttn ' onClick={() => navigate('/bhatts/satta')}>Satta and Balwand</button>
                            <button className='bhatt-bttn ' onClick={() => navigate('/bhatts/mardana')}>Bhai Mardana Ji</button>
                                    {/* <h4 className='intro-heading  mt-5'>Boundless scripture of guru granth sahib</h4> */}                                    
                                    {introData ?
                                        < div
                                        dangerouslySetInnerHTML={{
                                            __html: introData.html                                            
                                        }} 
                                        /> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loader && <Spinner />}
            </section>
            
        </div>
    )
}

export default BhattSahiban