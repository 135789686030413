import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from "react-router";
import { Link, useLocation } from "react-router-dom";
import '../../../assets/css/dashboard.css';
import '../../../assets/css/style.css';
import '../../../assets/css/sds.css';
import sdsbannar from '../../../assets/img/sds-banner.jpg';
import { ApiHelper } from '../../../helpers/ApiHelper';
import { API } from '../../../config/api';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function SriDarbarSahib() {
    const [loader, setLoader] = useState(false);
    const [date, setDate] = useState(new Date());
    const [hukumnama, setHukumnama] = useState([]);
    //const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
    useEffect(() => {
        getHukumnama(date)
    }, [])
    const getHukumnama = async (date) => {
        console.log('HUkumNama1111', moment(date).format('YYYY-MM-DD'));
        setLoader(true)
        await ApiHelper.get(API.getHukumnama + '?dt=' + moment(date).format('YYYY-MM-DD'))
            .then((resData) => {
                setLoader(false);
                console.log('HUkumNama', resData.data);
                setHukumnama(resData.data)
            })
            .catch((err) => {
                setLoader(false);
                /* setMessage("Error !!! Please try again"); */
                console.log(err, 'err');
            });
    }
    const handleChange = (selectedDate) => {
        setDate(selectedDate);
        getHukumnama(selectedDate);
      };
    return (
        <div>
            <section>
                <div class=" justify-content-md-center align-items-center">
                    <div class="banner-img">
                        <img src={sdsbannar} class="img-fluid" alt="Responsive image" />
                    </div>
                </div>
            </section>

            <section>
                <div className='container py-5'>
                    <div className='row'>
                        <div className=' col-md-4 '>
                        <audio src={`https://old.sgpc.net/audio/SGPCNET${moment(date).format('DDMMYY')}.mp3`} className='text-left' autoplay controls ></audio>
                            <p className='audio-text text-center' >Audio Hukumnama </p>
                        </div>

                        <div className=' col-md-4 text-center'>
                     
                            <button className='date-bttn'>
                                <div className='datename'> 
                                <DatePicker
                                selected={date}
                                onChange={handleChange}
                                dateFormat="dd-MM-yyyy"
                            />
                          <i class="bi bi-calendar"></i>
                          </div>
                          </button>
                            {/* <input type="date"
                                value={date}
                                onChange={(e) => { e.preventDefault(); setDate(e.target.value); getHukumnama(e.target.value); }} />
 */}
                        </div>

                        <div className=' col-md-4'>
                        <audio src={`https://old.sgpc.net/oldkatha/katha${moment(date).format('DDMMYY')}.mp3`} className=' text-right' autoplay controls ></audio>
                            <p className='audio-text text-center' >Katha of Hukumnama </p>
                        </div>
                    </div>
                </div>
            </section>

            <section >
                <div className='huku-date '>{hukumnama?.hukamdatetime}</div>
                <div className='punj-title'>{hukumnama?.titlePunjabi}</div>
                <div className='punj-content'>{hukumnama?.contentPunjabi}</div>
                <div className="text-container-footer">
                    <p className='punj-l-footer'>{hukumnama?.leftFooterPunjabi}</p>
                    <p className='punj-r-footer'>{hukumnama?.rightFooterPunjabi}</p>
                </div>
                <div className='viya-punj'>{hukumnama?.viyakhyaPunjabi}</div>
                <div className='title-eng'>{hukumnama?.titleEnglish}</div>
                <div className='content-english'>{hukumnama?.contentEnglish}</div>
            </section>
        </div>
    )
}

export default SriDarbarSahib