import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function SukhmaniSahib() {
    return (
        <div>
            <SGCommonPage title="Sukhmani sahib" apiName="sukhmani-sahib" audioName="sukhmani_sahib" tlPage="82" /> 
        </div>
    )
}

export default SukhmaniSahib