import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function ShabadHazare() {
    return (
        <div>
            <SGCommonPage title="Shabad Hazare Paatishahi 10" apiName="shabad-hazare-paatishahi-10" audioName="shabad_hazare_paatishahi_10" tlPage="4" /> 
        </div>
    )
}

export default ShabadHazare