import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function AnandSahib() {
    return (
        <div>
            <SGCommonPage title="Anand Sahib" apiName="anand-sahib" audioName="anand_sahib" tlPage="9"/> 
        </div>
    )
}

export default AnandSahib