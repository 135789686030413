import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function Aarti() {
    return (
        <div>
            <SGCommonPage title="Aartee" apiName="aarti" audioName="aarti" tlPage="4" /> 
        </div>
    )
}

export default Aarti