//Gurbani search//
import React, { useEffect, useState,useRef } from 'react';
import { useNavigate, useOutletContext } from "react-router";
import { Link, useLocation } from "react-router-dom";
import '../assets/css/dashboard.css';
import Axios from 'axios';
import { API } from "../config/api";
import { ApiHelper } from '../helpers/ApiHelper';
import charMap from '../components/GurumukhiAscii';
import VirtualKeyboard from '../components/VirtualKeyboard';
import styled from 'styled-components';
import ShareButtons from '../components/ShareComponent';
const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

const TextInput = styled.textarea`
  width: 400px;
  height: 100px;
  font-size: 1.5em;
  margin-bottom: 20px;
`;

function GurbaniSearching() {
    const [englishInput, setEnglishInput] = useState(''); // English input
  const [displayedInput, setDisplayedInput] = useState('');
    const transliterateToGurumukhi = (input) => {    
          return input
          .split('')
          .map((char) => charMap[char.toLowerCase()] || charMap[char.toUpperCase()] || char)
          .join('');
      };
      const handleInputChange = (e) => {
        const input = e.target.value;        
        // Check if the input length has decreased (backspace)
        if (input.length < displayedInput.length) {
          const newEnglishInput = englishInput.slice(0, -1);
          setEnglishInput(newEnglishInput);
          setDisplayedInput(transliterateToGurumukhi(newEnglishInput));
        } else {
          // Append the new character to the previous input
          const newChar = input[input.length - 1];
          const newEnglishInput = englishInput + newChar;
          setEnglishInput(newEnglishInput);
          setDisplayedInput(transliterateToGurumukhi(newEnglishInput));
        }
      };
    useEffect(() => {
        console.log("SEARCHVALUE", englishInput)
    }, [englishInput])

    // virtual Keyboard
    const [inputValue, setInputValue] = useState('');
    const [q, setQ] = useState('');
    const searchTextRef = useRef(null);
   /*  const handleKeyPress = (value) => {
      if (value === 'CapsLock') {
        // CapsLock is handled inside the VirtualKeyboard
      } else {
        setInputValue((prevValue) => prevValue + value);
      }
    }; */
    /* const onKeyPush = () => {
      const value = searchTextRef.current.value;
      setQ(value);
      //handleSearchChange();
    }; */
    /* const handleKeyPress = (value) => {
      setInputValue((prevValue) => prevValue + value);
      setDisplayedInput((prevValue) => prevValue + value);
    }; */
   /*  const handleKeyPress = (value) => {
      const newInputValue = displayedInput + value;
      setEnglishInput((prev) => prev + value);
      setDisplayedInput(newInputValue);
    }; */
    const handleKeyPress = (char) => {
      setInputValue((prev) => prev + char);
    };
    const onKeyPush = () => {
      console.log('Key pressed');
    };

    const addChar = (char) => {
      setInputValue((prevValue) => prevValue + char);
    };
    useEffect (() =>{
      console.log('LLLLLL',inputValue)
    },[inputValue])
   

//Share
const shareUrl = 'http://localhost:3000/gurbani-search';
const title = 'Search Gurbani : Gurbani Research Website';


    return (
        <div>
            <div>Welcome</div>
            <input 
        type="text" 
        value={displayedInput} // Display Gurumukhi ASCII in the input field
        onChange={handleInputChange} 
        placeholder="Type English letters" 
      />
      <input
        type="text"
        ref={searchTextRef}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Type or use the virtual keyboard"
      />
     
            <div>{englishInput}</div>
            <AppWrapper>
      {/* <VirtualKeyboard addChar={handleKeyPress}  /> */}
      <VirtualKeyboard addChar={addChar} />
    </AppWrapper>




<div className='mt-5 mx-5 p-5'>
<button className='p-3'> Share</button>
<ShareButtons url={shareUrl} title={title} />
</div>






        </div>
    )
}

export default GurbaniSearching