import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function RamkaliSadh() {
    return (
        <div>
            <SGCommonPage title="Ramkali Sadh" apiName="ramkali-sadh" audioName="ramkali_sadh" tlPage="2" /> 
        </div>
    )
}

export default RamkaliSadh