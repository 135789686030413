import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import '../assets/css/dashboard.css';
import '../assets/css/style.css';
import '../assets/css/footer.css';
import Logo from '../assets/icons/logo.svg';
import icon1 from '../assets/img/icon-1.svg';
import icon2 from '../assets/img/icon-2.svg';
import icon3 from '../assets/img/icon-3.svg';
import icon4 from '../assets/img/icon-4.svg';
const Footer = (props) => {
    
    return (
        <footer>
            <div class="seventh-container  common-padding" >
                <div className="container-fluid">
                    <div className="">
                        <div className='footer-logo'>
                            <img src={Logo} className="rounded mx-auto d-block footer-img mt-4" align="center" alt="Responsive image" />
                            {/*<img src={Groupicon} className="rounded mx-auto d-block footer-img mt-4" align="center" alt="Responsive image" />*/}
                            <div className='f-soc-icons'>
                                <Link data-mdb-ripple-init class="btn text-white btn-floating m-1" href="https://www.facebook.com/SearchGurbani" target="_blank"
                                    role="button">
                                    <img src={icon1} className="" align="center" alt="Responsive image" />
                                </Link>
                                <Link data-mdb-ripple-init class="btn text-white btn-floating m-1" href="https://twitter.com/intent/follow?source=followbutton&variant=1.0&screen_name=searchgurbani" target="_blank"
                                    role="button">
                                    <img src={icon2} className="" align="center" alt="Responsive image" />
                                </Link>
                                <Link data-mdb-ripple-init class="btn text-white btn-floating m-1" href="https://www.youtube.com/user/SearchGurbani/" target="_blank"
                                    role="button">
                                    <img src={icon3} className="" align="center" alt="Responsive image" />
                                </Link>
                                <Link data-mdb-ripple-init class="btn text-white btn-floating m-1" href="https://t.me/searchgurbani" target="_blank"
                                    role="button">
                                    <img src={icon4} className="" align="center" alt="Responsive image" />
                                </Link>




                            </div>

                        </div>

                        <div className='mt-4'>
                            <nav class="d-flex navbar navbar-default my-4 pre-f-links">
                                <ul class="mx-auto nav ">
                                    <li class="active"><Link to={`/music/page/${1}`}>Indian Classical Music and Sikh Kirtan</Link></li>
                                    <li><Link to='/'> Compilation of Sri Guru Granth Sahib ( Adi Birh Bare) </Link></li>
                                </ul>
                            </nav>
                        <nav>
                            <ul class="mx-auto nav ">
                                <li class="active"><Link to='/'>Home</Link></li>
                                <li><Link to='/preferences'>Site Preferences</Link></li>
                                <li><Link to='/unicode'>Unicode Fonts </Link></li>
                                <li><Link to='/feedback'> Feedback</Link></li>
                                <li><Link to='/'> Privacy Policy</Link></li>
                                <li><Link to='/site-map'> Sitemap </Link></li>
                                <li><Link to='/'> <span className='fontz-color'>Donate to SearchGurbani</span></Link></li>
                            </ul>
                        </nav>
                        </div>


                    </div>
                </div>
            </div>
            <div class="five-container-footer p-3" >
                <div class="container-lg">
                    <div class="row ">
                        <div className='copyright-wrp'>
                            <p className='footer-left-heading mb-0'>© SearchGurbani 2024 All rights reserved.</p>
                            <div className='d-flex t-c'>
                                <Link to='/'>Terms & Conditions </Link>
                                <Link to='/'>Privacy Policy </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer;
