import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function SavaiyeM4() {
    return (
        <div>
            <SGCommonPage title="Savaiye M:4" apiName="savaiye-m4" audioName="savaiye_m4" tlPage="13" />  
        </div>
    )
}

export default SavaiyeM4