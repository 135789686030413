import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function DGTvai() {
    return (
        <div>
            <SGCommonPage title="Tvai Prasadh Savaiye ( Dheenan Kee)" apiName="tvai-prasadh-savaiye-dheenan-kee" audioName="tvai_prasaadh_savaiye_dheenan_kee" tlPage="2" /> 
        </div>
    )
}

export default DGTvai