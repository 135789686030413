import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router";
import { Link, useLocation } from "react-router-dom";
import '../assets/css/dashboard.css';
import '../assets/css/style.css';
import '../assets/css/feedbck.css';
import searchbannar from '../assets/img/search-bannar.svg';
import Form from 'react-bootstrap/Form';

const Feedback = () => {
   
    return (
        <div >
               <section className='section-1'>
                <div className=" justify-content-md-center align-items-center">
                    <div className='banner-img'>
                        <img src={searchbannar} class="img-fluid" alt="Responsive image" />
                    </div>
                </div>
                <div className="fouth-container common-padding">
                        <div className="container align-items-center d-flex justify-content-center">
                            <div class="row w-60 search-main-grd">
                                <div class="col-12">
                                    <h1 className="main-heading">Send us your valuable feedback</h1>
                                </div>
                               
                               

                                <div class="filter">
                                    <div class="main-filters">
                                    <div className='row g-3'>
                                        <div class="col-lg-6">
                                            <div className="form-control auto-com-check">
                                                <input type="text"  name="fname" className="feed-inputs" placeholder="Name"/>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div className="form-control auto-com-check">
                                                <input type="text"  name="fname" className="feed-inputs" placeholder="Email ID"/>
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div className="form-control auto-com-check">
                                                <input type="text"  name="fname" className="feed-inputs" placeholder="Subject"/>
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div className="form-control auto-com-check feed-text">
                                            <textarea className="feed-txt" name="w3review" placeholder="Subject"></textarea>
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            
            </section>
      </div>
    )
}
export default Feedback;