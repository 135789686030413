import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function ChaupaiSahib() {
    return (
        <div>
            <SGCommonPage title="Chaupai Sahib" apiName="chaupai-sahib" audioName="chaupai_sahib" tlPage="5" /> 
        </div>
    )
}

export default ChaupaiSahib