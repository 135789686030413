import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from "react-router";
import { Link, useLocation, useParams } from "react-router-dom";
import '../assets/css/dashboard.css';
import '../assets/css/author.css';
import Axios from 'axios';
import { API } from "../config/api";
import { ApiHelper } from '../helpers/ApiHelper';
import Spinner from './Spinner';

function AkIndex(props) {
    const [loader, setLoader] = useState(false);
    const [indexArr, setIndexArr] = useState([]);
    const [charArr, setCharArr] = useState([]);
    useEffect(() => {
        if (props.language) {
            console.log('language', props.language)
            if (props.language === 'A') {
                getIndex('english', 'A');
            }
            else if (props.language === 'क') {
                getIndex('hindi', 'क');
            }
            else {
                getIndex('punjabi', 'ਕ');
            }
        }
    }, [])

    const getIndex = async (lang, char) => {
        setLoader(true)
        await ApiHelper.get(API.getAkEnglishIndex + lang + '?alpha=' + char)
            .then((resData) => {
                setLoader(false);
                console.log('Index', resData.data);
                setCharArr(resData.data.alphabets)
                setIndexArr(resData.data.shabads);
            })
            .catch((err) => {
                setLoader(false);
                console.log(err, 'err');
            });
    }
    const decodeEntities = (html) => {
        var txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    };
    return (
        <div>
            {loader && <Spinner />}
            <section className='inner-actions p-4' >
                <div className='container'>
                    <h3 className='text-dark mb-3 text-center' >Amrit Keertan - Shabads</h3>
                    <div className='alphabet-container text-center'>
                        {charArr.map((char, index) => (
                            <Link onClick={() => props.language === 'A' ? getIndex('english', decodeEntities(char)) : props.language === 'क' ? getIndex('hindi', decodeEntities(char)) : getIndex('punjabi', decodeEntities(char))}>
                                <span key={index} className='alphabet-char'>{decodeEntities(char)}</span></Link>
                        ))}
                    </div>
                </div>
            </section>


            <section>
                <div className='container'>
                    <div className='d-flex flex-column'>
                        <div className='ang-display '>
                            <div className="section_title">
                                <span className="col_sl_no"> No.</span>
                                <span className="col_sl_name">Shabad Title</span>
                                <span className="col_section_no">Page No.</span>
                                <br className="clearer" />
                            </div>

                            <div className='ang-wrapper '>
                                {indexArr.map((item, index) => {
                                    const shabadNameWithDashes = item.shabad_name.replace(/ /g, '-');
                                    const url = `/amrit-keertan/shabad/${item.shabad_id}/${shabadNameWithDashes}`;
                                    return (
                                        <div className='ang-itm' >
                                            <div className="section_line line row1">
                                                <span className="col_sl_no sec-no">{index + 1}</span>
                                                <Link to={`/amrit-keertan/shabad/${item.shabad_id}/${shabadNameWithDashes}`} className="col_sl_no sec-nos">
                                                    <span >{props.language === 'A' ? item.shabad_name : props.language === 'क' ? item.shabad_hindi : item.shabad_punjabi}</span></Link>
                                                    <Link to={`/amrit-keertan/shabad/${item.shabad_id}/${shabadNameWithDashes}`} className="col_section_no sec-no page-no">
                                                    <span >{item.pageno}</span></Link>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AkIndex