import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from "react-router";
import { Link, useLocation } from "react-router-dom";
import '../../../assets/css/dashboard.css';
import '../../../assets/css/style.css';
import '../../../assets/css/cyber.css';
import { API } from "../../../config/api";
import { ApiHelper } from '../../../helpers/ApiHelper';

function CyberHukumnama() {
    const [loader, setLoader] = useState(false);
    const [languageCheck, setLanguageCheck] = useState(false);
    const [pageInfo, setPageInfo] = useState([]);
    const [dataLines, setPDataLines] = useState([]);
    useEffect(() => {
        const angNo = Math.floor(Math.random() * 467 + 1).toString();
        console.log('555555555', angNo)
        getChapter(angNo);
    }, [])
    const getChapter = async (ang) => {
        setLoader(true)
        await ApiHelper.get(API.getCyberHukum + '?ang=' + ang + '&type=id')
            .then((resData) => {
                setLoader(false);
                console.log('chapter', resData.data);
                setPageInfo(resData.data)
                setPDataLines(resData.data.lines)
            })
            .catch((err) => {
                setLoader(false);
                /* setMessage("Error !!! Please try again"); */
                console.log(err, 'err');
            });
    }
    return (
        <div>
            <section>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-lg-12'>

                            <div className="top-divz " >
                                <h3 className="cyb-head">Hukumnama - Ang {pageInfo.pageno}</h3>
                                <p className="sub-cyb">{pageInfo.hukumnama_info?.title} in {pageInfo.hukumnama_info?.raag}</p>
                            </div>

                            <h6 className='mb-4 mt-4 head-cyber'>In Gurmukhi</h6>

                            <div className="cyber-section " >
                                {dataLines.map((item, index) => (
                                    <p className="sub-cyb-hindi">
                                        {item.punjabi}
                                    </p>
                                ))}
                            </div>

                            <h6 className='mb-4 mt-4 head-cyber'>Phonetic English</h6>

                            <div className="cyber-section " >
                                {dataLines.map((item, index) => (
                                    <p className="sub-cyb-eng">
                                        {item.translit}
                                    </p>
                                ))}
                            </div>


                            <h6 className='mb-4 mt-4 head-cyber'>English Translation</h6>

                            <div className="cyber-section " >
                                {dataLines.map((item, index) => (
                                    <p className="sub-cyb-eng-trans">
                                        {item.english}
                                    </p>
                                ))}
                            </div>


                            <h6 className='mb-4 mt-4 head-cyber'>Punjabi Viakhya</h6>

                            <div className="cyber-section " >
                                {dataLines.map((item, index) => (
                                    <p className="sub-cyb-punjab ">
                                        {item.ss_para}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*<section>
            <div className='container py-5'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div>
                            <h6>In Gurmukhi</h6>
                            <div className='cyber-section'>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>*/}

        </div>
    )
}

export default CyberHukumnama