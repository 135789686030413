import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function KirtanSohila() {
    return (
        <div>
            <SGCommonPage title="Kirtan Sohila" apiName="kirtan-sohila" audioName="kirtan_sohila" tlPage="3" /> 
        </div>
    )
}

export default KirtanSohila