import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import SGCommonPage from '../../../components/SGCommonPage';


function SalokM9() {
    return (
        <div>
            <SGCommonPage title="Salok M: 9" apiName="salok-m9" audioName="salok_m9" tlPage="5" /> 
        </div>
    )
}

export default SalokM9