import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from "react-router";
import { Link, useLocation } from "react-router-dom";
import '../assets/css/dashboard.css';
import '../assets/css/style.css';
import rightimg from '../assets/img/right-img.svg';
import astore from '../assets/img/apple-store.svg';
import pstore from '../assets/img/play-store.svg';
import mobilephn from '../assets/img/mobile.svg';
import inerlogo from '../assets/img/iner-logo-mid.svg';
import applelogo from '../assets/img/apple_icon.svg';
import windowlogo from '../assets/img/windows_icon.svg';
import hand from '../assets/img/hand.svg';
import Form from 'react-bootstrap/Form';
import 'animate.css';
import Modal from 'react-bootstrap/Modal';
import Axios from 'axios';
import { API } from "../config/api";
import { ApiHelper } from '../helpers/ApiHelper';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import charMap from '../components/GurumukhiAscii';
import VirtualKeyboard from '../components/VirtualKeyboard';
import Switch from 'react-switch';
import DataTable from 'react-data-table-component';
import GurbaniSearch from './GurbaniSearch';

function Home() {
    const navigate = useNavigate();
    const [isYoutube, setIsYoutube] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isAutocomplete, setIsAutocomplete] = useState(false);
    const [isKeyboard, setIsKeyBoard] = useState(true);
    const [selectedOption, setSelectedOption] = useState('FL_begin');
    const [selectedLanguage, setSelectedLanguage] = useState('ROMAN');
    const [searchData, setSearchData] = useState([]);
    const [englishInput, setEnglishInput] = useState(''); // English input
    const [displayedInput, setDisplayedInput] = useState('');
    const [authorArr, setAuthorArr] = useState([]);
    const [ragaArr, setRagaArr] = useState([]);
    const [categoryArr, setCategoryArr] = useState([]);
    const [isfilter, setIsFilter] = useState(true);
    const [selectedAuthor, setSelectedAuthor] = useState('');
    const [scripture, setScripture] = useState('ggs');
    const [pageFrom, setPageFrom] = useState('1');
    const [pageTo, setPageTo] = useState('1430');
    const [searchAllResult, setSearchAllResult] = useState([]);
    const [selectedRaag, setSelectedRaag] = useState('');
    const [selectedCat, setSelectedCat] = useState('');
    const [activeTab, setActiveTab] = useState('Granth');
    const [videoId, setVideoId] = useState('');
    const scriptureToTabEventKey = {
        ggs: 'Granth',
        ak: 'Keertan',
        bvg: 'Vaaran',
        dg: 'Dasam',
        ks: 'Savaiye',
        bnl: 'Nand',
    };
    useEffect(() => {
        getAuthors();
        getRaga();
        getCategory();
    }, [])
    const getAuthors = async () => {
        setLoader(true)
        await ApiHelper.get(API.getAuthor)
            .then((resData) => {
                setLoader(false);
                console.log('Author', resData);
                setAuthorArr(resData.data);
            })
            .catch((err) => {
                setLoader(false);
                console.log(err, 'err');
            });
    }
    const getRaga = async () => {
        setLoader(true)
        await ApiHelper.get(API.getRagas)
            .then((resData) => {
                setLoader(false);
                console.log('Raga', resData);
                setRagaArr(resData.data);
            })
            .catch((err) => {
                setLoader(false);
                console.log(err, 'err');
            });
    }
    const getCategory = async () => {
        setLoader(true)
        await ApiHelper.get(API.getAllCategory)
            .then((resData) => {
                setLoader(false);
                console.log('category', resData.data);
                setCategoryArr(resData.data);
            })
            .catch((err) => {
                setLoader(false);
                console.log(err, 'err');
            });
    }
    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
        console.log('Selected language:', event.target.value);
        setEnglishInput('');
        setDisplayedInput('');
    };
    const transliterateToGurumukhi = (input) => {
        return input
            .split('')
            .map((char) => charMap[char.toLowerCase()] || charMap[char.toUpperCase()] || char)
            .join('');
    };
    const handleInputChange = (e) => {
        const input = e.target.value;
        if (selectedLanguage === 'PUNJABI-ASC') {
            // Check if the input length has decreased (backspace)
            if (input.length < displayedInput.length) {
                const newEnglishInput = englishInput.slice(0, -1);
                setEnglishInput(newEnglishInput);
                setDisplayedInput(transliterateToGurumukhi(newEnglishInput));
            } else {
                // Append the new character to the previous input
                const newChar = input[input.length - 1];
                const newEnglishInput = englishInput + newChar;
                setEnglishInput(newEnglishInput);
                setDisplayedInput(transliterateToGurumukhi(newEnglishInput));
            }
        } else {
            setDisplayedInput(input);
        }
    };
    const handleItemClick = (item) => {
        setDisplayedInput(item.word);  // Set the selected word to input field
        setSearchData([]);  // Clear the dropdown
        setIsAutocomplete(false);
        getSearchResult(item.word);
    };
    const addChar = (char) => {
        setDisplayedInput((prevValue) => prevValue + char);
    };
    useEffect(() => {
        if (displayedInput !== "" && isAutocomplete === true) {
            getSearchWords();
            getSearchResult(selectedLanguage === 'PUNJABI-ASC' ? englishInput : displayedInput)
        }
        else if (displayedInput !== "" && isAutocomplete === false) {
            getSearchResult(selectedLanguage === 'PUNJABI-ASC' ? englishInput : displayedInput)
        }
        else {
            setSearchData([])
        }
        /* if (displayedInput === "") {
            setSearchData([])
        } */
    }, [displayedInput])
    useEffect(() => {
        if (englishInput === "") {
            setSearchData([])
        }
    }, [englishInput])

    const getSearchWords = async () => {
        setLoader(true)
        const params = new FormData();
        params.append('q', selectedLanguage === 'PUNJABI-ASC' ? englishInput : displayedInput);
        params.append('searchtype', selectedOption);
        params.append('language', selectedLanguage);
        params.append('ggs', 'true');
        params.append('ak', 'true');
        params.append('bgv', 'true');
        params.append('dg', 'true');
        params.append('ks', 'true');
        params.append('bnl', 'true')

        console.log('para', params)
        /* await ApiHelper.post(API.uploadFile, params) */
        await Axios.post(API.getAllSearchWords, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((resData) => {
                setLoader(false)
                console.log('getSearch', resData.data.allwords);
                setSearchData(resData.data.allwords)
            })
            .catch((err) => {
                setLoader(false)
                console.log(err);
            })
    }
    const handleFilter = (nextChecked) => {
        setIsFilter(nextChecked);
    };
    const getSearchResult = async (key) => {
        setLoader(true)
        const params = new FormData();
        params.append('start', '0');
        params.append('length', '20');
        params.append('Searchtype', selectedOption);
        params.append('language', selectedLanguage);
        params.append('scripture', scripture);
        params.append('SearchData', key ? key : displayedInput);
        params.append('author', selectedAuthor);
        params.append('raag', selectedRaag);
        params.append('page_from', pageFrom);
        params.append('page_to', pageTo)
        params.append('bnlSelect', selectedCat)

        console.log('para', params)
        /* await ApiHelper.post(API.uploadFile, params) */
        await Axios.post(API.getSearchData, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((resData) => {
                setLoader(false)
                console.log('getSearchRESULT', resData.data.data);
                setSearchAllResult(resData.data.data)
            })
            .catch((err) => {
                setLoader(false)
                console.log(err);
            })
    }
    const columns = [
        {
            name: 'ID',
            selector: 'serialNumber',
            sortable: false,
            cell: (row, index) => <div>{index + 1}</div>,
            width: '69px'
        },
        {
            name: "Phonetic Roman",
            selector: row => row.translit,
        },
        {
            name: "Gurumukhi Unicode",
            selector: row => row.punjabi,
        },
        {
            name: "",
            cell: (row) => (
                <div className='btn-dis'>
                    <button className='ang-btn'
                        onClick={() => navigate('/' + `${row.pageLink}`)}>Go to page</button>
                </div>
            )
        },
    ]
    const customStyleTable = {
        head: {
            style: {
                fontSize: '15px',
                fontWeight: 500,
                backgroundColor: "#F5F5F5"
            },
        },
        headRow: {
            style: {
                backgroundColor: '#66666',
                minHeight: '52px',
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
            },
        },
        rows: {
            style: {
                position: 'relative',
                cursor: 'pointer', // Change cursor to pointer for the whole row
            },
        },
    };
    const handleClick = (row) => {
        console.log('ROWWWW', row.shabadlink)
        /* setRowItem(row); */
        navigate('/' + `${row.shabadlink}`)
    }
    useEffect(() => {
        setActiveTab(scriptureToTabEventKey[scripture]);
    }, [scripture]);
    const handleTabSelect = (eventKey) => {
        console.log('Selected tab:', eventKey);
        setActiveTab(eventKey);
        switch (eventKey) {
            case 'Granth':
                setPageFrom('1');
                setPageTo('1430');
                setScripture('ggs');
                break;
            case 'Keertan':
                setPageFrom('65');
                setPageTo('1040');
                setScripture('ak');
                break;
            case 'Vaaran':
                setPageFrom('1');
                setPageTo('41');
                setScripture('bvg');
                break;
            case 'Dasam':
                setPageFrom('1');
                setPageTo('2820');
                setScripture('dg');
                break;
            case 'Savaiye':
                setPageFrom('1');
                setPageTo('675');
                setScripture('ks');
                break;
            case 'Nand':
                setPageFrom('1');
                setPageTo('150');
                setScripture('bnl');
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        if (scripture) {
            getSearchResult(selectedLanguage === 'PUNJABI-ASC' ? englishInput : displayedInput);
        }
    }, [scripture, pageFrom, pageTo]);
    const handleYoutubeClose = () => setIsYoutube(false);
    const handleYoutubeShow = () => setIsYoutube(true);
    const getMostRecentVideoId = async () => {
        const playlistId = 'UUtMzmAmF_zS3ryeADetzzKg'; // YouTube playlist ID
        const apiKey = 'AIzaSyBMlJs853beIdarfkd-wiEX9uruS8-Lcv0'; // Your YouTube Data API key
        const response = await fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=1&playlistId=${playlistId}&key=${apiKey}`);
        const data = await response.json();
        if (data.items && data.items.length > 0) {
            return data.items[0].snippet.resourceId.videoId;
        }
        return null;
    }
    const openMostRecentVideoInNewTab = async () => {
        //setLoading(true);
        const videoId = await getMostRecentVideoId();
        if (videoId) {
            setVideoId(videoId);
            setIsYoutube(true);
           // window.open(`https://www.youtube.com/watch?v=${videoId}`, '_blank');
        } else {
            alert('Failed to fetch the most recent video.');
        }
        //setLoading(false);
    }

    return (
        <div>
            <section className='banner-section'>
                {/* <div className=" justify-content-md-center align-items-center">
                    <div className='banner-img'>
                        <img src={bannar} class="img-fluid" alt="Responsive image" />
                    </div>
                </div> */}
            </section>

            <section>

                {/*---first---**/}
                <div className="container">
                    <div className="second-container">
                        <div className="row ">
                            <div class="col-lg-7">
                                <div class="px-1 py-1 align-middle">
                                    <h4 className='second-heading  mt-5'>Pathway to God:  Sri Guru Granth Sahib Ji</h4>
                                    <p className='second-para  mt-4'> Sri Guru Granth Sahib is indeed unique in its thought,
                                        literary expression and the message it continues to communicate centuries after it was written.
                                        Exalted thought needs to be transported on the vehicle of language to reach the masses.
                                        Poetic expression lifts prose to a higher plane. When verse and music meld,
                                        their beauty and sweetness makes mind transcend the humdrum of rational existence.</p>
                                    <div className=" py-2">
                                        {/*  <button type="button" class="btn btn-outline-primary second-bttn"
                                            onClick={(e) => { e.preventDefault(); setIntroMore(!introMore); }}> {introMore ? "Show Less" : "Discover More"}</button> */}
                                        <button type="button" class="btn btn-outline-primary second-bttn"
                                            onClick={(e) => { e.preventDefault(); navigate('/dicover-more') }}>Discover More</button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-5  mt-5">
                                <img src={rightimg} class="img-fluid" alt="Responsive image" />
                            </div>
                        </div>
                    </div>
                </div>
                {/*---end first---**/}

                {/*---second---**/}
                <div className="third-container bck-color mt-5" >
                    <div className="container">
                        <div className='row'>
                            <div class="my-5">
                                {/*<h2>Meet Our Expert</h2>*/}
                                <p className='third-para'>On auspicious occasion of 321st Foundation Day of Khalsa Panth,
                                    Vaisakhi 2020, Gateway to Sikhism is proud to launch SearchGurbani.com
                                    the Fifth Edition with Instant Gurbani Search and Dynamic Prefernces for
                                    every scripture. NEW Sundar Gutka Section and SGGS World Section with
                                    Sri Guru Granth Sahib ji with 53 Translations and 22 Transliterations
                                    adapted from BaniDB and improved content and functionality under Resources
                                    . Go to Site Preferences for all options available for you.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/*---end second---**/}

                {/*---third---**/}
                {/* <div className="fouth-container common-padding">
                        <div className="container align-items-center d-flex justify-content-center">
                            <div class="row w-60 search-main-grd">
                                <div class="col-12">
                                    <h1 className="main-heading">Instant Gurbani Search</h1>
                                </div>
                                <p className="paragraph">
                                     Instantly search Sri Guru Granth Sahib, Amrit Keertan Gutka, Bhai Gurdas Vaaran, Sri Dasam Granth ,
                                     Kabit Bhai Gurdas and Bhai Nand Lal Bani by typing your keywords:
                                </p>
                                <div className="position-relative">
                                    <input className="form-control border-secondary py-2 search-r" type="search" placeholder='Search' />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary search-divz" type="button">
                                            <i className="bi bi-search"></i>
                                            </button>
                                        </div>
                                </div>

                                <div class="filter">
                                    <div class="main-filters">
                                    <div className='row g-3'>
                                        <div class="col-lg-3">
                                            <div className="form-control auto-com-check">
                                                <label for="vehicle1" className='check-label'>Autocomplete</label>
                                                <input type="checkbox" className='checkbox' value="Bike" />
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div className="form-control auto-com-check">
                                                <label for="vehicle1" className='check-label'>Advance Search</label>
                                                <input type="checkbox" className='checkbox' value="Bike" />
                                            </div>
                                        </div>

                                        <div class="col-lg-3">

                                            <Form.Select aria-label="Default select example">
                                                <option selected >Return Results</option>
                                                <option value="1">First Letter Begining</option>
                                                <option value="2">First Letter Anywhere</option>
                                                <option value="3">Phrase</option>
                                             </Form.Select>
                                        </div>

                                        <div class="col-lg-3">
                                            <Form.Select aria-label="Default select example">
                                                <option selected>Find results in language</option>
                                                <option value="1">Phonetic Roman</option>
                                                <option value="2">Gurumukhi ASCII</option>
                                                <option value="3">Gurumukhi Unicode</option>
                                            </Form.Select>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                <GurbaniSearch advanceSearch="true" />
                {/*---end third---**/}

                <section className='gurbani-video' >
                    <div className='container'>
                        <div className='row w-100'>
                            <div className='video-wrapper'>
                                <h1 className='main-heading text-light' >Gurbani Videos</h1>
                                <Link className='play'  onClick={openMostRecentVideoInNewTab}><i className="bi bi-play-circle"></i>Play Video</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='gurbani-Hukumnamas p-5' >
                    <div className='container'>
                        <div className='row w-100'>
                            <div className='video-wrapper'>
                                <h1 className='main-heading text-light' >Hukumnamas</h1>
                                <div className='huk-link' >
                                    <Link className='play' onClick={(e) => { e.preventDefault(); navigate('/hukum') }}>Harmandir Sahib <i class="me-5 bi bi-arrow-right-circle"></i></Link>
                                    <Link className='play' onClick={(e) => { e.preventDefault(); navigate('/hukumnama/cyber') }} > Cyber Hukum  <i class="bi bi-arrow-right-circle"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='gurbani-tips common-padding'>
                    <div className='container'>
                        <div className='row w-100'>
                            <h1 className='main-heading text-start' >Gurbani Search Tips</h1>
                            <ul>
                                <li>Please click on 'Preferences' tab in Top and Bottom Menu Bar or -=Here=- to set your languages preferences.</li>
                                <li>Instructions to Download and install Fonts</li>
                                <li>Search option "First Letter Beginning" searches for verses beginning with the "first alphabet of the words in the verse in sequence"</li>
                                <li>Search option "First Letter Anywhere" searches for the "first alphabet of the words in sequence" anywhere in the verse including beginning.</li>
                                <li>Search option "Phrase " searches for the exact phrase anywhere in the verse including beginning.</li>
                                <li>Dropdown Suggestion and autocomplete options available for all searches.</li>
                                <li>Pootha Maatha Ki Assees : example keywords: p m k a; pt m k a; pt mt k ; pt mt k as; and so on..</li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section className='app-download common-padding' >
                    <div className='container'>
                        <div className='row'>
                            <div className='dw-wrapper'>
                                <div className='download-today'>
                                    <div className='dwld-img'>
                                        <img src={mobilephn} class="img-fluid dwnld-mobile" alt="Responsive image" />
                                    </div>
                                    <div className='download-details'>
                                        <h1 className='download-now' >Download Today</h1>
                                        <p className='paragraph'>
                                            SearchGurbani.com proudly announces the release of iSearchGurbani 2.6.0 for the
                                            iPhone & iPad at Apple iTunes store. and for v. 3.1.0 Android Platform at Google Play Store.
                                        </p>
                                        <h6>iSearchGurbani Features:</h6>
                                        <div className='features-g'>
                                            <ul>
                                                <li>-SGGS Ang by Ang . SGGS Chapter Index</li>
                                                <li>-SGGS Shabad Index</li>
                                                <li>-Bhai Gurdas Vaaran Index</li>
                                                <li>-Kabit by Kabit</li>
                                            </ul>
                                            <ul>
                                                <li>-Sri Dasam Granth Chapter Index</li>
                                                <li>-Sri Dasam Granth Page by Page</li>
                                                <li>-Bhai Nand Lal Baani Index ...View More</li>
                                            </ul>
                                        </div>
                                        <div className='download-btns'>
                                            <Link className='store-download' to="https://itunes.apple.com/us/app/isearch-gurbani-!/id674467937?ls=1&amp;mt=8">
                                                <img src={astore} class="img-fluid donate" alt="Responsive image" />
                                            </Link>
                                            <Link className='store-download' to="https://play.google.com/store/apps/details?id=com.smartbuzz.vkh.isg&amp;hl=en">
                                                <img src={pstore} class="img-fluid donate" alt="Responsive image" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="desktop-download common-padding" >
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='dw-wrapper'>
                                    <div className='desktop-download-itm'>
                                        <div>
                                            <img src={inerlogo} class="img-fluid web-dw-logo" alt="Responsive image" />
                                        </div>
                                        <div>
                                            <h1 className='main-heading' >iSearchGurbani4 (Windows/Mac/Linux):</h1>
                                        </div>
                                        <div>
                                            <p className='paragraph' > is a cross platform software bringing you a simplistic approach to search and explore Gurbani .
                                                iSG includes complete Sri Guru Granth Sahib , Bhai Gurdas Vaaran , Kabit Bhai Gurdas, Bhai
                                                Nand Lal Baani and Baani's from Sri Dasam Granth Sahib. iSearchGurbani ( iSG) has a built
                                                in slideshow/projector feature, which automatically displays text to an additional monitor
                                                or projector screen configured as extended monitor. iSearchGurbani v4 is cross platform
                                                software, can be installed on Windows/ MAC OSx/ Linux
                                            </p>
                                        </div>



                                        <div className='download-btns'>
                                            <Link className='store-download' to="/sgdv/isg">
                                                <img src={windowlogo} class="img-fluid desk-icon" alt="Responsive image" />
                                                Download for Windows
                                            </Link>
                                            <Link className='store-download' to="/sgdv/isg">
                                                <img src={applelogo} class="img-fluid desk-icon" alt="Responsive image" />
                                                Download for Mac
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='gurbani-video' >
                    <div className='container'>
                        <div className='row w-100'>
                            <div className='video-wrapper'>
                                <h1 className='main-heading text-light only-web' >Click Here to Donate for development of iSearchGurbani</h1>
                                <h1 className='main-heading text-light only-mobile' >Click Here to </h1>
                                <Link className='play' to="/home">
                                    <img src={hand} class="img-fluid donate" alt="Responsive image" />
                                    Donate Now</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </section>


            <Modal className="play-modals"  show={isYoutube} onHide={handleYoutubeClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Play Videos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='socia-share'>
                        {videoId && (
                            <iframe
                                /* width="560"
                                height="315" */
                                 className="custom-iframe"
                                src={`https://www.youtube.com/embed/${videoId}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        )}
                    </div>

                </Modal.Body>
            </Modal>



        </div>
    )
}

export default Home